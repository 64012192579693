import SpaceMenu from '@layouts/sidebar/spaces/SpaceMenu';
import { NotificationProvider } from '../NotificationProvider';
import TwoColumnLayout from './TwoColumn';
import ActiveSubscriptionWrapper from '../ActiveSubscriptionWrapper';
import { useParams } from 'react-router';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { useEffect } from 'react';
import RequireAuth from '../AuthWrapper';

export default function SpacesLayout() {
  const { workspaceId } = useParams();
  const setFocusedWorkspaceId = useWorkspaceStore(
    (state) => state.setFocusedWorkspaceId
  );

  useEffect(() => {
    setFocusedWorkspaceId(workspaceId ?? null);
  }, [workspaceId]);

  return (
    <RequireAuth>
      <ActiveSubscriptionWrapper>
        <NotificationProvider>
          <TwoColumnLayout>
            <SpaceMenu />
          </TwoColumnLayout>
        </NotificationProvider>
      </ActiveSubscriptionWrapper>
    </RequireAuth>
  );
}
