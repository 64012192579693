import type { DateFieldInstance } from '@spaceduck/api';
import clsx from 'clsx';
import { useState } from 'react';

import { getDisplayDate } from '@/utils/date';
import {
  formatDateForPersisting,
  getDate,
} from '@components/category/cellContent/Date';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import DatePicker from '@ui/DatePicker';
import type { CommonEditProps, CommonProps } from '../InfoCategories';
import styles from './Date.module.scss';
import Empty from './Empty';

function formatDateForDisplay(
  year: string | null,
  month: string | null,
  day: string | null
) {
  if (!(year && month && day)) {
    return null;
  }

  const _year = Number(year);
  const _month = Number(month);
  const _day = Number(day);

  const date = new Date(_year, _month - 1, _day);

  return getDisplayDate(date);
}

export default function DateField(commonProps: CommonProps) {
  const { canEdit, instances, updateField } = commonProps;
  const [shouldShowEditView, setShouldShowEditView] = useState(false);
  const dateInstance = (instances as DateFieldInstance[])[0];

  const [year, month, day] = dateInstance
    ? dateInstance.date.split('-')
    : [null, null, null];

  const handleClick = () => {
    if (canEdit) {
      setShouldShowEditView(true);
    }
  };

  return (
    <div className={clsx(!shouldShowEditView && styles.hoverBg)}>
      {shouldShowEditView && updateField ? (
        <EditView
          dateInstance={dateInstance}
          setShouldShowEditView={setShouldShowEditView}
          updateField={updateField}
        />
      ) : (
        <>
          {dateInstance ? (
            <div className={styles.truncate} onClick={handleClick}>
              {formatDateForDisplay(year, month, day)}
            </div>
          ) : (
            <Empty onClick={() => setShouldShowEditView(true)} />
          )}
        </>
      )}
    </div>
  );
}

const EditView = ({
  dateInstance,
  setShouldShowEditView,
  updateField,
}: CommonEditProps & {
  dateInstance?: DateFieldInstance;
}) => {
  const { containerRef } = useOnClickOutside<HTMLDivElement>({
    callback: () => setShouldShowEditView(false),
  });

  const handleChange = async (rawDate: Date | null) => {
    const date = rawDate ? formatDateForPersisting(rawDate) : null;
    await updateField?.(
      date ? ([{ ...dateInstance, date }] as DateFieldInstance[]) : []
    );
    setShouldShowEditView(false);
  };

  return (
    <div className={styles.control} ref={containerRef}>
      <DatePicker
        autoComplete="off"
        autoFocus
        className={styles.datePicker}
        dateFormat="dd-MM-yyyy"
        isClearable
        onValueChange={(date) => handleChange(date)}
        popperPlacement="bottom-end"
        selected={dateInstance?.date ? getDate(dateInstance.date) : undefined}
        showTimeSelect={false}
      />
    </div>
  );
};
