import { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useNavigate, useParams } from 'react-router';

import {
  type AiModel,
  type Chat,
  type ChatTemporaryLinkSource,
  type ChatMediaGroupSource,
  type ChatTemporaryFileSource,
  exists,
} from '@spaceduck/api';
import { Icon24 } from '@spaceduck/icons';

import { useCreateChatSession } from '@api/ai';
import { useProject } from '@api/project';
import { toastApiErrorOr } from '@api/util';
import { ChatHistory } from '@components/ai/ChatHistory';
import ChatMessageInput from '@components/ai/ChatMessageInput';
import Sidebar from '@components/ai/Sidebar';
import Head from '@components/Head';
import Button from '@ui/Button';
import PageHeader from '../projects/common/PageHeader';
import ProjectTabs from '../projects/common/ProjectTabs';
import sharedStyles from './SharedStyles.module.scss';
import styles from './ResearchAssistantIndex.module.scss';
import { ChatContent } from './ResearchAssistant';
import { urlFor } from '@/urls';
import { useInteractWithChatMessage } from '@/hooks/useInteractWithChatMessage';

const { ChatHistory: ChatHistoryIcon } = Icon24;

export default function ResearchAssistantIndex() {
  const { mutateAsync: createChatSessionInternal } = useCreateChatSession();
  const {
    mutateAsync: createChatSession,
    status,
    data: chatSessionData,
  } = useInteractWithChatMessage(createChatSessionInternal);
  const navigate = useNavigate();
  const [temporaryChatSession, setTemporaryChatSession] = useState<Chat>();
  const [showChatHistory, setShowChatHistory] = useState(false);
  const projectId = useParams<{ projectId: string }>().projectId!;

  const { data: project } = useProject(projectId);
  const submitChatSession = useCallback(
    async (
      query: string,
      mediaGroups: ChatMediaGroupSource[],
      links: ChatTemporaryLinkSource[],
      temporaryFiles: ChatTemporaryFileSource[],
      model: AiModel
    ) => {
      if (!projectId) {
        return false;
      }
      setTemporaryChatSession({
        projectId,
        id: 'temp',
        label: query,
        mediaGroups,
        messages: [
          {
            id: '',
            pending: true,
            query,
            response: {
              parts: [],
            },
          },
        ],
        model,
      });
      try {
        await createChatSession({
          projectId,
          model,
          mediaGroupIds: mediaGroups.map((mediaGroup) => mediaGroup.id),
          links: links.map((link) => link.id).filter(exists),
          temporaryFiles: temporaryFiles.map((file) => file.id),
          initialQuery: query,
        });
      } catch (error) {
        toastApiErrorOr(error, 'Failed to create new chat session', {
          iconVariant: 'warning',
          titleText: 'Error creating chat session',
          bodyText:
            'An unknown error occurred starting your assistant chat session. Please try again later',
        });
        setTemporaryChatSession(undefined);
        return false;
      }
      return true;
    },
    [projectId]
  );

  useEffect(() => {
    if (chatSessionData?.chatSessionId) {
      navigate(
        urlFor('researchAssistantChat', {
          chatId: chatSessionData.chatSessionId,
        }),
        {
          state: { fromIndex: true },
        }
      );
    }
  }, [chatSessionData]);

  if (!projectId) {
    return;
  }

  const isDisabled = status === 'success' || status === 'pending';

  return (
    <>
      <Head title={`${project?.project?.label || 'Space'} Research Assistant`} />
      <PageHeader />
      <ProjectTabs
        showSidebarToggle={false}
        cta={
          <>
            <Button size="sm" variant="outlined">
              New chat
            </Button>
            <Button
              onClick={() => setShowChatHistory(!showChatHistory)}
              size="sm"
              variant="outlined"
              isSquare
            >
              <ChatHistoryIcon size={20} />
            </Button>
          </>
        }
      />
      <div className={styles.content}>
        <div
          className={clsx(
            styles.chatContent,
            temporaryChatSession ? styles.tempChat : undefined
          )}
        >
          {temporaryChatSession && (
            <div className={styles.tempChat}>
              <ChatContent
                messages={temporaryChatSession.messages}
                sources={temporaryChatSession?.mediaGroups}
                chatId={temporaryChatSession.id}
              />
            </div>
          )}
          <div className={clsx(styles.createChat, isDisabled && styles.disabled)}>
            <h1>What would you like to know?</h1>
            <ChatMessageInput
              projectId={projectId}
              readOnly={isDisabled}
              onSubmit={submitChatSession}
              showTools
            />
          </div>
        </div>
        <Sidebar open={showChatHistory}>
          <h5 className={sharedStyles.chatHistoryTitle}>Chat history</h5>
          <ChatHistory projectId={projectId} />
        </Sidebar>
      </div>
    </>
  );
}
