import { useMutation, useQueryClient } from '@tanstack/react-query';
import { usePollTimeout } from './usePollTimeout';
import {
  ApiError,
  getPendingInteractionStatus,
  type PendingChatInteractionSchema,
} from '@spaceduck/api';
import { researchChatKeys } from '@/api/ai';
import { asMilliseconds } from '@spaceduck/utils';

export const useInteractWithChatMessage = <Params>(
  interactionFn: (params: Params) => Promise<PendingChatInteractionSchema>
) => {
  const isNotPending = (res: Awaited<ReturnType<typeof getPendingInteractionStatus>>) =>
    res.status !== 'pending';
  const pollWithTimeout = usePollTimeout(
    500,
    isNotPending,
    getPendingInteractionStatus,
    asMilliseconds({ minutes: 1 })
  );

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params: Params) => {
      const pendingInteraction = await interactionFn(params);
      const res = await pollWithTimeout(pendingInteraction.messageId);
      if (res.status === 'failed') {
        throw new ApiError({
          body: 'There has been an error interacting with ducklas. Try again later.',
          errorKind: 'error',
          title: 'There has been an error',
        });
      }
      return res;
    },

    onSuccess: (resp) => {
      queryClient.invalidateQueries({
        queryKey: researchChatKeys.one(resp.chatSessionId),
      });
    },
  });
};
