import RequireAuth from '@/components/AuthWrapper';
import Wizard from '@components/onboarding/Wizard';

export default function OnboardingPage() {
  return (
    <RequireAuth>
      <Wizard />
    </RequireAuth>
  );
}
