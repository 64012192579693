import type { BillingPlan } from '@spaceduck/api';
import { useNavigate } from 'react-router';

import useWorkspaceId from '@/hooks/useWorkspaceId';
import { urlFor } from '@/urls';
import { useManagementModal } from '@ui/ManagementModal';
import type { ReactNode } from 'react';

const buildModalProps = (subscriptionInfo?: {
  plan: BillingPlan;
  assignedSeats: number;
  seats: number;
}) => {
  if (!subscriptionInfo) {
    return {
      messageNode: <></>,
      continueText: '',
    };
  }
  let messageNode: ReactNode =
    "Contributor seats are designated for contributors in roles such as 'Editor', 'Admin', or 'Owner', granting them editing privileges or higher within your workspace. Viewers, however, can access content at no cost.";
  const continueText = 'Manage seats';

  messageNode = (
    <>
      {messageNode}
      <br />
      <br />
      {`${subscriptionInfo.assignedSeats} / ${subscriptionInfo.seats} Contributor seats in use`}
    </>
  );

  return {
    messageNode,
    continueText,
  };
};

export function useSeatManagementModal(subscriptionInfo?: {
  plan: BillingPlan;
  assignedSeats: number;
  seats: number;
}) {
  const workspaceId = useWorkspaceId();
  const navigate = useNavigate();
  const { messageNode, continueText } = buildModalProps(subscriptionInfo);
  const { open: openManagementModal } = useManagementModal({
    header: 'Seat management',
    title: 'Contributor seats',
    message: messageNode,
    onContinue: () => {
      workspaceId &&
        navigate(
          urlFor('workspaceSettingsBillingAndPlan', {
            workspaceId,
          })
        );
    },
    cancelText: 'Never mind',
    continueText: continueText,
  });

  return {
    open: () => openManagementModal(),
  };
}
