import type { Dispatch } from 'react';

import { RadioButton } from './index';
import styles from './ProfessionSelection.module.scss';
import { type Profession, professions } from '@spaceduck/api';

type ProfessionSelectionProps = {
  profession: Profession;
  setProfession: Dispatch<React.SetStateAction<Profession>>;
};

export const ProfessionSelection = ({
  profession,
  setProfession,
}: ProfessionSelectionProps) => {
  return (
    <div className={styles.professionSelection}>
      {professions.map((_profession) => {
        return (
          <RadioButton
            isActive={_profession === profession}
            key={_profession}
            name="profession"
            onSelect={() => setProfession(_profession)}
            selected={profession}
            value={_profession}
          />
        );
      })}
    </div>
  );
};
