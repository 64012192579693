import { useContext } from 'react';
import { useReactFlow } from '@xyflow/react';
import { useShallow } from 'zustand/shallow';

import { exists } from '@spaceduck/api';

import { useBoardStore } from './useBoardStore';
import { useHistory } from './useHistory';
import { useNodes } from './useNodes';
import { BoardStoreContext } from '../context/boardContext';
import {
  useCopyHotkey,
  useCutHotkey,
  useGroupSelectedHotkey,
  useDuplicateHotkey,
  usePasteHotkey,
  useRedoHotkey,
  useSelectAllHotkey,
  useUndoHotkey,
} from '@hooks/useShortcuts';
import createToast from '@/utils/createToast';
import { useFrames } from './useFrames';

const NEW_FRAME_OFFSET = 20;

export const useBoardShortKeys = () => {
  const boardContext = useContext(BoardStoreContext);
  const { clipboardItems, selectedNodes, setSelectedEdges, setSelectedNodes } =
    useBoardStore(
      useShallow((state) => ({
        clipboardItems: state.clipboardItems,
        selectedNodes: state.selectedNodes,
        setSelectedEdges: state.setSelectedEdges,
        setSelectedNodes: state.setSelectedNodes,
      }))
    );

  const { addFrame } = useFrames();
  const { cutNodes, copyNodes, duplicateNode, pasteNodes } = useNodes();
  const { duplicateFrame } = useFrames();
  const {
    flowToScreenPosition,
    getEdges,
    getNode,
    getNodes,
    getNodesBounds,
    setEdges,
    setNodes,
  } = useReactFlow();
  const { pause, resume, redo, undo } = useHistory();

  useUndoHotkey(
    (ev) => {
      if (ev.shiftKey) return;
      undo();
    },
    {
      preventDefault: true,
    },
    [boardContext]
  );

  useRedoHotkey(
    () => {
      redo();
    },
    {
      preventDefault: true,
    },
    [boardContext]
  );

  usePasteHotkey(
    () => {
      pasteNodes?.();
    },
    {
      enabled: !!clipboardItems,
    },
    {
      preventDefault: true,
    }
  );

  useCopyHotkey(
    () => {
      if (selectedNodes.length) {
        copyNodes();
      }
    },
    {
      enabled: selectedNodes.length > 0,
      preventDefault: true,
    }
  );

  useCutHotkey(
    () => {
      if (selectedNodes.length) {
        cutNodes();
      }
    },
    {
      enabled: selectedNodes.length > 0,
      preventDefault: true,
    }
  );

  useDuplicateHotkey(
    () => {
      if (selectedNodes.length === 1) {
        const id = selectedNodes[0];
        if (id) {
          const node = getNode(id);

          if (!node) return;

          if (node.type === 'groupNode') {
            duplicateFrame?.(id);
            return;
          }

          duplicateNode?.(id);
        }
      }
    },
    {
      enabled: selectedNodes.length === 1,
      preventDefault: true,
    }
  );

  useSelectAllHotkey(
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();

      pause();
      setNodes((nodes) => nodes.map((node) => ({ ...node, selected: true })));
      setEdges((edges) => edges.map((edge) => ({ ...edge, selected: true })));
      setSelectedEdges(getEdges().map((edge) => edge.id));
      setSelectedNodes(getNodes().map((node) => node.id));

      Promise.resolve().then(() => {
        resume();
      });
    },
    {
      preventDefault: true,
    },
    []
  );

  useGroupSelectedHotkey(
    () => {
      const allNodes = getNodes();
      const nodes = selectedNodes
        .map((node) => allNodes.find((_node) => node === _node.id))
        .filter(exists);

      const hasGroupedNode = nodes.find((node) => node.parentId);

      if (hasGroupedNode) {
        createToast({
          titleText: 'Group creation failed',
          bodyText: 'One or more nodes are already in a Frame',
          iconVariant: 'warning',
        });
        return;
      }

      const bounds = getNodesBounds(selectedNodes);
      const { x, y } = flowToScreenPosition({
        x: bounds.x - NEW_FRAME_OFFSET,
        y: bounds.y - NEW_FRAME_OFFSET,
      });

      addFrame(x, y, {
        width: bounds.width + NEW_FRAME_OFFSET * 2,
        height: bounds.height + NEW_FRAME_OFFSET * 2,
        nodes: selectedNodes,
      });
    },
    {
      enabled: selectedNodes.length > 1,
      preventDefault: true,
    },
    [selectedNodes, getNodes]
  );

  return {};
};
