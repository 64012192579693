import { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';

import {
  isProject,
  type ListWorkspaceProjectsResponse,
  type ListWorkspaceProject,
  type WorkspaceDetail,
} from '@spaceduck/api';
import { Icon24, Icon64 } from '@spaceduck/icons';

import {
  useBulkDeleteMediaGroups,
  useBulkTagMediaGroups,
  useCopyMediaGroup,
  useListMediaGroups,
} from '@api/mediaGroup';
import { isCapable } from '@api/util';
import { useWorkspaceProjects } from '@api/workspace';
import { useCreateProjectModal } from '@components/admin/CreateProjectsModal';
import { BulkSelectionToolbar } from '@components/BulkSelectionToolbar';
import FileUploadWrapper from '@components/FileUploadWrapper';
import MediaGroupGrid from '@components/MediaGroupGrid';
import { NewMediaGroupDropdown } from '@components/NewMediaGroupDropdown';
import PlaceholderCard from '@components/PlaceholderCard';
import ProjectListItem, { ProjectStackListItem } from '@components/ProjectListItem';
import { useBatchUpdate } from '@hooks/useBatchUpdate';
import useDeleteMediaGroupConfirmModal from '@hooks/useDeleteMediaGroupConformModal';
import { useFileUploadWrapper } from '@hooks/useFileUploadWrapper';
import useMoveMediaGroupConfirmModal from '@hooks/useMoveMediaGroupConfirmModal';
import { useSelectedMediaGroups } from '@hooks/useSelectedMediaGroups';
import Button from '@ui/Button';
import { knownErrors } from '@/const';
import { TopNav } from '../settings/workspace/common';
import styles from './Homepage.module.scss';
import Spinner from '@/components/Spinner';
import { Carousel, type CarouselOptions } from '@/components/Carousel';

const MEDIA_GROUPS_VIDEO = 'https://www.youtube.com/embed/S7hSwBMlRhM';
const SPACES_VIDEO = 'https://www.youtube.com/embed/WfcWVlehi14';
const { Add, Project, RepositoryBrowse } = Icon24;
const { Library, Projects } = Icon64;
const DISPLAY_NONE = { display: 'none' } as const;

export default function Homepage({ workspace }: { workspace: WorkspaceDetail }) {
  const { open: openCreateProjectModal } = useCreateProjectModal({
    canCreateProject: isCapable('create', workspace.capabilities),
    redirectOnCreate: false,
  });
  const { handleChange } = useFileUploadWrapper({ workspaceId: workspace.id });

  return (
    <>
      <TopNav owner="home" currentBreadcrumb="All" title={workspace.label ?? 'Home'}>
        <div className={styles.topButtons}>
          <Button onClick={openCreateProjectModal} size="sm" variant="outlined">
            New space
          </Button>
          <NewMediaGroupDropdown
            align="end"
            showTriggerIcon={false}
            triggerText="New item"
            variant="outlined"
          />
        </div>
      </TopNav>
      <div className={styles.container}>
        <div className={styles.section}>
          <SectionHeader icon={<Project />} text="Recent Spaces">
            <Button onClick={openCreateProjectModal} size="sm" variant="icon">
              <Add size={20} />
            </Button>
          </SectionHeader>
          <SpacesList workspaceId={workspace.id} />
        </div>
        <div className={styles.section}>
          <SectionHeader icon={<RepositoryBrowse />} text="Recent Items">
            <NewMediaGroupDropdown align="end">
              <Button size="sm" type="button" variant="icon">
                <Add size={20} />
              </Button>
            </NewMediaGroupDropdown>
          </SectionHeader>
          <FileUploadWrapper>
            <MediaGroupList workspaceId={workspace.id} />
          </FileUploadWrapper>
          <input type="file" multiple onChange={handleChange} style={DISPLAY_NONE} />
        </div>
      </div>
    </>
  );
}

const SectionHeader = ({
  children,
  icon,
  text,
}: { children: React.ReactNode; icon: React.ReactNode; text: string }) => {
  return (
    <div className={styles.sectionHeader}>
      {icon}
      {text}
      {children}
    </div>
  );
};

const SpacesList = ({ workspaceId }: { workspaceId: string }) => {
  const {
    data: projectData,
    isLoading,
    error,
  } = useWorkspaceProjects(workspaceId, { sort: 'open' });

  if (isLoading) {
    return <Spinner />;
  }

  if (error || !projectData) {
    throw new Error(knownErrors.workspaceError, { cause: error });
  }

  const projects = projectData.projects;

  if (!projects.length) return <NewSpace projectData={projectData} />;

  return <SpacesCarousel projects={projects} />;
};

const NewSpace = ({ projectData }: { projectData: ListWorkspaceProjectsResponse }) => {
  const { open: openCreateProjectModal } = useCreateProjectModal({
    canCreateProject: isCapable('create', projectData.capabilities),
    redirectOnCreate: false,
  });
  const [showLightbox, setShowLightbox] = useState(false);

  return (
    <>
      <PlaceholderCard
        containerClassName={styles.placeholderCard}
        icon={<Projects />}
        title="New Space"
        description={
          <p>
            Spaces let you kickstart a research project, curate a collection of
            inspiration, or effortlessly organize your content.
          </p>
        }
      >
        <div className={styles.buttons}>
          <Button
            iconBefore={<Add />}
            onClick={openCreateProjectModal}
            size="sm"
            variant="primary"
          >
            New space
          </Button>
          <Button onClick={() => setShowLightbox(true)} size="sm" variant="outlined">
            Learn more
          </Button>
        </div>
      </PlaceholderCard>
      <Lightbox
        open={showLightbox}
        close={() => setShowLightbox(false)}
        slides={[
          {
            src: SPACES_VIDEO,
          },
        ]}
        render={{
          buttonNext: () => null,
          buttonPrev: () => null,
          slide: () => (
            <div>
              <iframe
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                height={630}
                referrerPolicy="strict-origin-when-cross-origin"
                src={SPACES_VIDEO}
                title="Spaceduck Spaces"
                width={1120}
              />
            </div>
          ),
        }}
      />
    </>
  );
};

const SPACES_CAROUSEL_OPTIONS: CarouselOptions = {
  align: 'start',
  skipSnaps: false,
};

const SpacesCarousel = ({ projects }: { projects: ListWorkspaceProject[] }) => {
  return (
    <Carousel options={SPACES_CAROUSEL_OPTIONS}>
      {projects.map((project) => {
        return (
          <div className={styles.carouselSlide} key={project.id}>
            {isProject(project) ? (
              <ProjectListItem
                className={styles.carouselItem}
                project={project}
                redirectOnDelete={false}
              />
            ) : (
              <ProjectStackListItem
                className={styles.carouselItem}
                key={project.id}
                stack={project}
              />
            )}
          </div>
        );
      })}
    </Carousel>
  );
};

const NewMediaGroup = () => {
  const [showLightbox, setShowLightbox] = useState(false);

  return (
    <>
      <PlaceholderCard
        containerClassName={styles.placeholderCard}
        icon={<Library />}
        title="New Item"
        description={
          <p>
            Build your hub for ideas, where files are magically organized to inspire and
            drive deep research.
          </p>
        }
      >
        <div className={styles.buttons}>
          <NewMediaGroupDropdown
            align="start"
            triggerText="New item"
            variant="primary"
          />
          <Button onClick={() => setShowLightbox(true)} size="sm" variant="outlined">
            Learn more
          </Button>
        </div>
      </PlaceholderCard>
      <Lightbox
        open={showLightbox}
        close={() => setShowLightbox(false)}
        slides={[
          {
            src: MEDIA_GROUPS_VIDEO,
          },
        ]}
        render={{
          buttonNext: () => null,
          buttonPrev: () => null,
          slide: () => (
            <div>
              <iframe
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                height={630}
                referrerPolicy="strict-origin-when-cross-origin"
                src={MEDIA_GROUPS_VIDEO}
                title="Spaceduck Items"
                width={1120}
              />
            </div>
          ),
        }}
      />
    </>
  );
};

const MediaGroupList = ({ workspaceId }: { workspaceId: string }) => {
  const {
    data: mediaGroupData,
    error,
    isError,
    isLoading,
  } = useListMediaGroups(workspaceId, {
    sort: 'newest',
  });
  const { selectedMediaGroupIds, selectedMediaGroups, onSelected, clearSelected } =
    useSelectedMediaGroups();
  const { mutateAsync: bulkCopyMediaGroups } = useCopyMediaGroup();
  const { mutateAsync: bulkDeleteMediaGroups } = useBulkDeleteMediaGroups();
  const { mutateAsync: bulkTagMediaGroups } = useBulkTagMediaGroups();
  const moveMediaGroupsConfirmModal = useMoveMediaGroupConfirmModal({
    onConfirm: clearSelected,
  });
  const openDeleteMediaGroupConfirmModal = useDeleteMediaGroupConfirmModal({
    onConfirm: async (ids: Set<string>) => {
      bulkDeleteMediaGroups(Array.from(ids));
    },
    onSuccess: () => {
      clearSelected();
    },
  });
  const { handleCategoryChange, handleStatusChange } = useBatchUpdate();

  if (isLoading) {
    return <Spinner />;
  }

  if (error || !mediaGroupData) {
    throw new Error(knownErrors.workspaceError, { cause: error });
  }

  const mediaGroups = mediaGroupData.pages.flatMap((page) => page.mediaGroups);

  if (!mediaGroups.length) {
    return <NewMediaGroup />;
  }

  return (
    <>
      {selectedMediaGroupIds.size > 0 && (
        <BulkSelectionToolbar
          className={styles.bulkSelectionToolbar}
          count={selectedMediaGroupIds.size}
          onAddTag={(tag) => {
            bulkTagMediaGroups({
              mediaGroupIds: Array.from(selectedMediaGroupIds),
              tags: [tag],
            });
          }}
          onCancel={clearSelected}
          onCopyMediaGroup={(projectId) => {
            bulkCopyMediaGroups({
              mediaGroupIds: Array.from(selectedMediaGroupIds),
              projectId,
              mode: 'copy',
            });
            clearSelected();
          }}
          onDelete={() => openDeleteMediaGroupConfirmModal(selectedMediaGroupIds)}
          onMoveMediaGroup={(projectId) => {
            moveMediaGroupsConfirmModal.open({
              mediaGroupIds: Array.from(selectedMediaGroupIds),
              projectId,
            });
          }}
          onUpdateCategory={(category) =>
            handleCategoryChange(selectedMediaGroups, category)
          }
          onUpdateStatus={(status) => handleStatusChange(selectedMediaGroups, status)}
          selectedMediaGroups={selectedMediaGroups}
        />
      )}
      <MediaGroupGrid
        containerClassName={styles.mediaGroupGrid}
        isError={isError}
        isFetchingNextPage={false}
        isLoading={isLoading}
        mediaGroups={mediaGroups}
        onSelected={onSelected}
        selected={selectedMediaGroupIds}
      />
    </>
  );
};
