import type { ReactNode } from 'react';

import styles from './Frame.module.scss';

export const Frame = ({
  children,
}: {
  children: ReactNode;
}) => {
  return <div className={styles.frame}>{children}</div>;
};
