import {
  createContext,
  type Dispatch,
  type SetStateAction,
  useState,
  type ReactNode,
  useEffect,
} from 'react';
import { useLocation } from 'react-router';
import { z } from 'zod';

import { createLocalStorageHook } from '@hooks/useLocalStorage';

export type ExpandedFavoritedSpaces = string[];
export type MenuState = {
  expandedFavoritedSpaces: string[];
  setExpandedFavoritedSpaces?: Dispatch<SetStateAction<ExpandedFavoritedSpaces>>;
  highlightSpacesLink: boolean;
};

const useExpandedFavoritedSpaces = createLocalStorageHook({
  key: 'expandedFavoritedSpaces',
  initial: [],
  schema: z.string().array(),
});

export const MenuContext = createContext<MenuState>({
  expandedFavoritedSpaces: [],
  setExpandedFavoritedSpaces: undefined,
  highlightSpacesLink: false,
});

export const MenuContextProvider = ({ children }: { children: ReactNode }) => {
  const { value: expandedFavoritedSpaces, setValue: setExpandedFavoritedSpaces } =
    useExpandedFavoritedSpaces();

  const [highlightSpacesLink, setHighlightSpacesLink] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setHighlightSpacesLink(location.pathname.startsWith('/spaces/'));
  }, [location]);

  return (
    <MenuContext.Provider
      value={{
        expandedFavoritedSpaces,
        setExpandedFavoritedSpaces,
        highlightSpacesLink,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
