import type { ReactNode } from 'react';
import clsx from 'clsx';

import icon from '@assets/img/icon-line.svg';
import text from '@assets/img/icon-text.svg';
import styles from './Header.module.scss';

export const Header = ({
  className,
  children,
}: {
  className?: string;
  children?: ReactNode;
}) => {
  return (
    <div className={clsx(styles.header, className)}>
      <div className={styles.branding}>
        <img src={icon} alt="" />
        <img src={text} alt="Spaceduck" />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
