import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { now } from 'lodash';
import { type Edge, type Node, useReactFlow } from '@xyflow/react';

import { exists } from '@spaceduck/utils';

import { useConfirmModal } from '@ui/ConfirmModal';
import { BoardStoreContext } from '../context/boardContext';
import type { GroupNodeType } from '../types/board';
import { nodeBorderColors } from '../types/colors';
import { useBoardStore } from './useBoardStore';
import {
  COPY_INDICATOR,
  DEFAULT_X_OFFSET,
  DEFAULT_Y_OFFSET,
  ID_DELIMITER,
} from './useNodes';
import { usePersist } from './usePersist';
import { useShallow } from 'zustand/shallow';

export const DEFAULT_FRAME_HEIGHT = 600;
export const DEFAULT_FRAME_WIDTH = 800;
const DEFAULT_BORDER_COLOR = nodeBorderColors[0];

const findAbsolutePosition = (
  currentNode: Node,
  allNodes: Node[]
): { x: number; y: number } => {
  if (!currentNode?.parentId) {
    return { x: currentNode.position.x, y: currentNode.position.y };
  }

  const parentNode = allNodes.find((node) => node.id === currentNode.parentId);
  if (!parentNode) {
    return { x: currentNode.position.x, y: currentNode.position.y };
  }

  const parentPosition = findAbsolutePosition(parentNode, allNodes);
  return {
    x: parentPosition.x + currentNode.position.x,
    y: parentPosition.y + currentNode.position.y,
  };
};

export const useFrames = () => {
  const boardContext = useContext(BoardStoreContext);

  const { setClipboardItems, selectedNodes, sortNodes } = useBoardStore(
    useShallow((state) => ({
      setClipboardItems: state.setClipboardItems,
      selectedNodes: state.selectedNodes,
      sortNodes: state.sortNodes,
    }))
  );

  const {
    addNodes,
    deleteElements,
    getEdges,
    getIntersectingNodes,
    getNode,
    getNodes,
    screenToFlowPosition,
    setNodes,
    updateNode,
  } = useReactFlow();

  const { updateEntry } = usePersist({ mediaGroupId: boardContext?.mediaGroupId });

  const updateFrame = useCallback(
    (id: string, patch: Partial<Node>) => {
      if (!boardContext?.mediaGroupId) return;

      const nodes = getNodes();
      const frame = nodes.find((node) => node.id === id);

      if (!frame) return;

      setNodes(
        nodes.map((node) => {
          if (node.id !== id) return node;

          return { ...node, ...patch, data: { ...node.data, ...patch.data } };
        })
      );
    },
    [boardContext, getNodes, setNodes]
  );

  // For observing and effects
  const [target, setTarget] = useState<Node | null | undefined>(null);
  const [lastTarget, setLastTarget] = useState<Node | null | undefined>(null);
  const [lastNode, setLastNode] = useState<Node | null | undefined>(null);

  // For real time check
  const _target = useRef<Node | null | undefined>(null);
  const _lastTarget = useRef<Node | null | undefined>(null);
  const _lastNode = useRef<Node | null | undefined>(null);

  const dragRef = useRef<Node | null>(null);

  const onNodeDragStart = useCallback((_: React.MouseEvent, node: Node) => {
    dragRef.current = node;
  }, []);

  const onNodeDrag = useCallback(
    (_: React.MouseEvent, node: Node) => {
      // calculate the center point of the node from position and dimensions
      if (node.measured?.width && node.measured.height) {
        // find overlapping nodes
        const intersectingNodes = getIntersectingNodes(node);

        setTarget(intersectingNodes ? intersectingNodes[0] : null);
        setLastTarget(intersectingNodes ? intersectingNodes[0] : null);
        setLastNode(node);

        _target.current = intersectingNodes ? intersectingNodes[0] : null;
        _lastTarget.current = intersectingNodes ? intersectingNodes[0] : null;
        _lastNode.current = node;
      }
    },
    [getIntersectingNodes, setTarget, setLastTarget, setLastNode]
  );

  const onNodeDragStop = useCallback(() => {
    setTarget(null);
    _target.current = null;
    dragRef.current = null;
  }, [setTarget]);

  const confirm = useConfirmModal<{ node: Node; target: Node }>({
    title: `Add to ${target?.data.label ?? 'Frame'}?`,
    confirmVariant: 'primary',
    confirmText: 'Yes, add to Frame',
    onConfirm: async (vars: { node: Node; target: Node }) => {
      if (!vars) return;
      const { target } = vars;

      // Order is important - load parent before child
      const allNodes = getNodes();

      const targetAbsPos = findAbsolutePosition(target, allNodes);

      const nodes = sortNodes().map((existingNode) => {
        if (selectedNodes.includes(existingNode.id)) {
          const node = getNode(existingNode.id);

          if (!node || node.type === 'groupNode') return existingNode;

          const nodeAbsPos = findAbsolutePosition(node, allNodes);
          const x = nodeAbsPos.x - targetAbsPos.x;
          const y = nodeAbsPos.y - targetAbsPos.y;

          return {
            ...existingNode,
            parentId: target.id,
            extent: 'parent',
            position: {
              x,
              y,
            },
          } as Node;
        }

        return existingNode;
      });

      setNodes(nodes);
      setLastNode(null);
      setLastTarget(null);
    },
    onCancel: () => {
      setLastNode(null);
      setLastTarget(null);
    },
  });

  useEffect(() => {
    if (
      lastNode &&
      lastTarget &&
      _lastNode.current &&
      !_target.current &&
      _lastTarget.current
    ) {
      if (_lastNode.current.type === 'groupNode' || _lastNode.current.parentId) return;
      if (_lastTarget.current.type !== 'groupNode') return;
      if (_lastTarget.current.data.expanded === false) return;

      // Close multiple instances due to delay
      confirm.close();
      confirm.open({ node: lastNode, target: lastTarget });
    }
  }, [lastNode, lastTarget, target]);

  const addFrame = useCallback(
    (
      x: number,
      y: number,
      options?: {
        nodes?: string[];
        width?: number;
        height?: number;
      }
    ) => {
      const node: GroupNodeType = {
        id: `group${now()}${ID_DELIMITER}${now()}`,
        data: {
          label: null,
          color: DEFAULT_BORDER_COLOR,
          expanded: true,
        },
        position: screenToFlowPosition({
          x,
          y,
        }),
        className: 'grey',
        type: 'groupNode',
        width: options?.width ?? DEFAULT_FRAME_WIDTH,
        height: options?.height ?? DEFAULT_FRAME_HEIGHT,
      };

      const allNodes = sortNodes();

      if (options?.nodes?.length) {
        const targetAbsPos = findAbsolutePosition(node, allNodes);

        const updatedNodes = allNodes.map((_node) => {
          if (!options.nodes?.includes(_node.id)) return _node;

          const nodeAbsPos = findAbsolutePosition(_node, allNodes);
          const x = nodeAbsPos.x - targetAbsPos.x;
          const y = nodeAbsPos.y - targetAbsPos.y;

          return {
            ..._node,
            data: {
              ..._node.data,
            },
            parentId: node.id,
            extent: 'parent',
            position: {
              x,
              y,
            },
          } as Node;
        });

        node.selected = true;

        setNodes([node, ...updatedNodes]);
      } else {
        setNodes([node, ...allNodes]);
      }
    },
    [screenToFlowPosition, sortNodes, setNodes]
  );

  const getRemainingNodesAndEdges = useCallback(
    ({
      deletedEdges,
      deletedNodes,
    }: { deletedEdges: Edge[]; deletedNodes: Node[] }) => {
      const edges = getEdges();
      const nodes = getNodes();

      const deletedEdgesIds = deletedEdges.map((edge) => edge.id);
      const deletedNodesIds = deletedNodes.map((node) => node.id);

      const remainingEdges = edges.filter((edge) => !deletedEdgesIds.includes(edge.id));
      const remainingNodes = nodes.filter((node) => !deletedNodesIds.includes(node.id));

      return {
        edges: remainingEdges,
        nodes: remainingNodes,
      };
    },
    [getEdges, getNodes]
  );

  const cutFrame = useCallback(
    async (id: string) => {
      const frameData = getFrameAndChildren(id, getNodes());

      if (!frameData?.frame) return null;

      const nodesToRemove = [frameData.frame, ...frameData.children].filter(exists);
      const selectedNodes = nodesToRemove.map((node) => node.id);
      if (!selectedNodes?.length) return;

      const edgesToRemove = getEdges().filter((edge) => {
        const handles = edge.id.split('->');
        if (handles.length !== 2 || !handles[0] || !handles[1]) return false;

        if (selectedNodes.includes(handles[0]) || selectedNodes.includes(handles[1]))
          return true;

        return false;
      });

      setClipboardItems({ nodes: nodesToRemove, edges: edgesToRemove });

      const { deletedEdges, deletedNodes } = await deleteElements({
        nodes: nodesToRemove,
        edges: edgesToRemove,
      });

      const { nodes, edges } = getRemainingNodesAndEdges({
        deletedEdges,
        deletedNodes,
      });

      updateEntry({
        patch: {
          board: {
            nodes,
            edges,
          },
        },
        showToast: false,
      });
    },
    [getNode, deleteElements, getRemainingNodesAndEdges, updateEntry]
  );

  const copyFrame = useCallback(
    (id: string) => {
      const frameData = getFrameAndChildren(id, getNodes());

      if (!frameData?.frame) return null;

      const nodesToRemove = [frameData.frame, ...frameData.children].filter(exists);
      const selectedNodes = nodesToRemove.map((node) => node.id);

      if (!selectedNodes?.length) return;

      const edgesToRemove = getEdges().filter((edge) => {
        const handles = edge.id.split('->');
        if (handles.length !== 2 || !handles[0] || !handles[1]) return false;

        if (selectedNodes.includes(handles[0]) || selectedNodes.includes(handles[1]))
          return true;

        return false;
      });

      setClipboardItems({
        nodes: nodesToRemove
          .map((node) => {
            if (!node) return undefined;

            return {
              ...node,
              id: `${node.id}${COPY_INDICATOR}`,
            };
          })
          .filter(exists),
        edges: edgesToRemove
          .map((edge) => {
            if (!edge) return undefined;

            return edge;
          })
          .filter(exists),
      });
    },
    [getFrameAndChildren, getNodes, getEdges, setClipboardItems]
  );

  const deleteFrame = useCallback(
    async (id: string) => {
      const allNodes = getNodes();
      const deletedNodesIds = allNodes
        .filter((node) => {
          if (node.id === id || node.parentId) return true;

          return false;
        })
        .map((node) => node.id);

      const { deletedEdges, deletedNodes } = await deleteElements({
        nodes: deletedNodesIds.map((id) => ({ id })),
      });

      const { nodes, edges } = getRemainingNodesAndEdges({
        deletedEdges,
        deletedNodes,
      });

      updateEntry({
        patch: {
          board: {
            nodes,
            edges,
          },
        },
        showToast: false,
      });
    },
    [getNodes, deleteElements, getRemainingNodesAndEdges, updateEntry]
  );

  const duplicateFrame = useCallback(
    async (id: string) => {
      const node = getNode(id);

      if (!node) {
        console.error('Node copy failed - could not find node');
        return;
      }

      addNodes(
        duplicateNodes(
          {
            ...node,
            position: {
              x: node.position.x + DEFAULT_X_OFFSET,
              y: node.position.y + DEFAULT_Y_OFFSET,
            },
          },
          getNodes()
        )
      );

      updateNode(node.id, { ...node, selected: false });
    },
    [getNode, addNodes, updateNode]
  );

  return {
    addFrame,
    copyFrame,
    cutFrame,
    deleteFrame,
    duplicateFrame,
    getFrameAndChildren,
    onNodeDragStart,
    onNodeDrag,
    onNodeDragStop,
    updateFrame,
    getIntersectingNodes,
  };
};

function getFrameAndChildren(id: string, allNodes: Node[]) {
  const frameData = allNodes.reduce<{ frame: Node | null; children: Node[] }>(
    (acc, curr) => {
      if (curr.id === id) {
        acc.frame = curr;
        return acc;
      }

      if (curr.parentId === id) {
        acc.children.push(curr);
        return acc;
      }

      return acc;
    },
    { frame: null, children: [] }
  );

  if (!frameData.frame) return null;

  return frameData;
}

function duplicateNodes(frame: Node, allNodes: Node[]): Node[] {
  const timeStamp = now();
  const newId = `${frame.id.split(ID_DELIMITER)[0]}${ID_DELIMITER}${timeStamp}`;

  const frameData = getFrameAndChildren(frame.id, allNodes);

  if (!frameData) {
    console.log('Cannot retrieve frame data');
    return [];
  }

  const newFrame = {
    ...frame,
    id: newId,
  };

  const newChildren = frameData?.children
    .map((node, idx) => {
      return {
        ...node,
        parentId: newId,
        id: `${node.id.split(ID_DELIMITER)[0]}${ID_DELIMITER}${now() + idx}`,
      };
    })
    .filter(exists);

  return [newFrame, ...newChildren];
}
