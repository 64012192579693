import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu';
import { clsx } from 'clsx';
import { useMemo, useState } from 'react';
import { Link } from 'react-router';

import { isProject, type ProjectDTO, type ProjectStackDTO } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';
import { pluralize } from '@spaceduck/utils';

import {
  useAddProjectToStack,
  useJoinProject,
  usePatchProject,
  useRemoveUserFromProject,
} from '@api/project';
import StatusBadge from '@components/StatusBadge';
import { useEditProjectModal } from '@components/admin/CreateProjectsModal';
import { ProjectMode as ProjectModeIcon } from '@components/icons';
import { useUserInfo } from '@hooks/useAuth';
import { trackEvent } from '@lib/analytics/google';
import { css } from '@lib/css';
import Button from '@ui/Button';
import MenuSearch, { type MenuSearchProps } from '@components/MenuSearch';
import DropdownMenu, { RecursiveDropdownMenuItem } from '@ui/DropdownMenu';
import ScrollArea from '@ui/ScrollArea';
import Tooltip from '@ui/Tooltip';
import UserAvatar from '@ui/UserAvatar';
import { copyTextToClipboard } from '@utils/copyToClipboard';
import createToast from '@utils/createToast';
import { absoluteUrlFor, urlFor } from '@/urls';
import styles from './ProjectListItem.module.scss';
import {
  useDeleteStackModal,
  useStackManagementModal,
} from './projects/StackManagementModal';
import useWorkspaceId from '@/hooks/useWorkspaceId';
import { useWorkspaceProjects } from '@/api/workspace';
import { stringContains } from '@/utils/string';

const { Add, MenuMore } = Icon16;
const { Favorite, FollowSubscribe, Link: LinkIcon, Settings, Stack, Remove } = Icon24;

type ProjectListItemProps = {
  className?: string;
  onClick?: () => void;
  project: ProjectDTO;
  redirectOnDelete?: boolean;
  removeFromStack?: (projectId: string) => void;
  stackMode?: 'add' | 'remove';
};

const MAX_DISPLAYED_USERS = 8;

export default function ProjectListItem({
  className,
  onClick,
  project,
  removeFromStack,
  redirectOnDelete,
  stackMode,
}: ProjectListItemProps) {
  const {
    id,
    label,
    description,
    status,
    membersPreview,
    totalPosts,
    capabilities,
    stackId,
  } = project;
  const hiddenUsersCount = membersPreview.length - MAX_DISPLAYED_USERS;
  const editCapability = capabilities?.find(({ capability }) => capability === 'edit');
  const canEdit = editCapability?.capable ?? false;

  const { open: openEditProjectModal } = useEditProjectModal(project, redirectOnDelete);
  const { mutateAsync: joinProject } = useJoinProject();
  const { mutateAsync: removeUserFromProject } = useRemoveUserFromProject();
  const { mutateAsync: patchProject } = usePatchProject();
  const user = useUserInfo();
  const [stackSearchQuery, setStackSearchQuery] = useState('');
  const handleInputFocus = (ev: React.KeyboardEvent) => {
    if (ev.key !== 'Escape') {
      ev.stopPropagation();
    }
  };

  const setFollowing = async (newIsFollowing: boolean) => {
    if (!user) {
      return;
    }

    if (newIsFollowing) {
      await joinProject({ projectId: project.id });
      createToast({
        titleText: 'Following space',
        bodyText: `Joined "${project.label}"`,
        iconVariant: 'success',
      });
    } else {
      await removeUserFromProject({ projectId: project.id, userId: user.id });
      createToast({
        titleText: 'Stopped following space',
        bodyText: `left "${project.label}"`,
        iconVariant: 'success',
      });
    }
  };

  const setIsStarred = async (isStarred: boolean) => {
    await patchProject({ id: project.id, patch: { isStarred } });
    createToast({
      titleText: 'Favorites Updated',
      bodyText: isStarred
        ? `Favorited "${project.label}"`
        : `Unfavorited "${project.label}"`,
      iconVariant: 'success',
    });
  };

  const url = urlFor('space', { projectId: id });

  const trackSelect = () => {
    trackEvent('select_content', {
      content_type: 'project',
      content_id: project.id,
    });
  };

  return (
    <div className={clsx(styles.projectListItem, className)}>
      <div className={styles.top}>
        <div className={styles.header}>
          <h4 className={styles.title}>
            <Link
              to={url}
              onClick={() => {
                onClick?.();
                trackSelect();
              }}
            >
              <ProjectModeIcon mode={project.mode} set={24} size={20} />
              {label}
            </Link>
          </h4>
          <DropdownMenu
            align="end"
            className={styles.projectDropdownMenu}
            triggerContent={
              <Button variant="icon" size="sm" className={styles.menu}>
                <MenuMore color="#B5B7CA" size={20} />
              </Button>
            }
          >
            <div className={clsx('menu', styles.projectMenu)}>
              <DropdownMenuItem
                asChild
                onSelect={() => setFollowing?.(!project.isFollowing)}
              >
                <Button
                  variant="menu"
                  iconBefore={
                    <FollowSubscribe active={project.isFollowing} size={20} />
                  }
                >
                  {project.isFollowing ? 'Following' : 'Follow'}
                </Button>
              </DropdownMenuItem>
              <DropdownMenuItem
                asChild
                onSelect={() => setIsStarred?.(!project.isStarred)}
              >
                <Button
                  variant="menu"
                  iconBefore={<Favorite active={project.isStarred} size={20} />}
                >
                  {project.isStarred ? 'Favorited' : 'Favorite'}
                </Button>
              </DropdownMenuItem>
              <DropdownMenuItem
                asChild
                onSelect={() =>
                  copyTextToClipboard(
                    absoluteUrlFor('space', { projectId: id }).toString(),
                    {
                      titleText: undefined,
                      bodyText: 'Space link copied to your clipboard!',
                    }
                  )
                }
              >
                <Button variant="menu" iconBefore={<LinkIcon size={20} />}>
                  Copy Link
                </Button>
              </DropdownMenuItem>
              {canEdit && (
                <DropdownMenuItem
                  asChild
                  onSelect={() => {
                    openEditProjectModal?.();
                  }}
                >
                  <Button variant="menu" iconBefore={<Settings size={20} />}>
                    Settings
                  </Button>
                </DropdownMenuItem>
              )}
              {stackMode === 'add' && (
                <RecursiveDropdownMenuItem
                  isPadded
                  item={{
                    content: (
                      <Button
                        className={styles.submenuTrigger}
                        variant="menu"
                        iconBefore={<Stack size={20} />}
                      >
                        Add to Stack
                      </Button>
                    ),
                    subMenu: [
                      {
                        content: (
                          <StackBrowser
                            onChange={(ev) =>
                              setStackSearchQuery(ev.currentTarget.value)
                            }
                            value={stackSearchQuery}
                            onKeyDown={handleInputFocus}
                            placeholder="Search stacks..."
                            project={project}
                          />
                        ),
                        isMenuItem: false,
                        className: styles.sticky,
                      },
                    ],
                  }}
                />
              )}
              {stackMode === 'remove' && canEdit && stackId && (
                <DropdownMenuItem
                  asChild
                  onSelect={() => {
                    removeFromStack?.(project.id);
                  }}
                >
                  <Button variant="menu" iconBefore={<Stack size={20} />}>
                    Remove from stack
                  </Button>
                </DropdownMenuItem>
              )}
            </div>
          </DropdownMenu>
        </div>
        {description && <div className={styles.description}>{description}</div>}
      </div>
      <div className={styles.bottom}>
        <div className={styles.userAndStatus}>
          <div className={styles.followers}>
            {membersPreview?.length > 0 && (
              <ul>
                {membersPreview
                  .slice(0, Math.min(membersPreview.length, MAX_DISPLAYED_USERS))
                  .map((member, idx) => (
                    <li key={idx}>
                      <Tooltip content={member.name}>
                        <span>
                          <UserAvatar
                            imageUrl={member.avatarUrl}
                            name={member.name}
                            size="xs"
                          />
                        </span>
                      </Tooltip>
                    </li>
                  ))}
                {hiddenUsersCount > 0 && (
                  <li className={styles.hiddenUsersCount}>{`+${hiddenUsersCount}`}</li>
                )}
              </ul>
            )}
          </div>
          {status && (
            <StatusBadge
              background={2}
              color={status === 'none' ? 3 : 2}
              status={status}
            />
          )}
        </div>
      </div>
      <div className={styles.footer}>
        <div
          className={styles.posts}
        >{`${totalPosts} ${pluralize(totalPosts !== 1, 'items', 'item')}`}</div>
      </div>
    </div>
  );
}

const StackBrowser = (props: MenuSearchProps & { project: ProjectDTO }) => {
  const workspaceId = useWorkspaceId();
  const { data: stacksData } = useWorkspaceProjects(workspaceId, { onlyStacks: true });
  const addStackCapability = stacksData?.capabilities.find(
    (c) => c.capability === 'createStack'
  );
  const stacks = stacksData?.projects.filter((p) => !isProject(p)) || [];
  const { value, project } = props;

  const { mutateAsync: addProjectToProjectStack } = useAddProjectToStack();
  const { open: openStackManagementModal } = useStackManagementModal();
  const stacksToRender = useMemo(
    () =>
      stacks.filter((stack) =>
        stringContains(stack.label, value, { ignoreCase: true })
      ),
    [stacks, value]
  );

  return (
    <div className={styles.stackBrowser}>
      <MenuSearch {...props} />
      {stacksToRender.length ? (
        <ScrollArea
          style={css({
            '--maxHeight':
              'calc(var(--radix-dropdown-menu-content-available-height) - var(--size-24) - var(--size-8))',
          })}
        >
          {stacksToRender.map(({ id, label, capabilities }) => {
            const canEditStack = capabilities.find(
              (c) => c.capability === 'editStack'
            )?.capable;
            if (!canEditStack) {
              return;
            }
            return (
              <Button
                onClick={() => {
                  addProjectToProjectStack({
                    projectId: project.id,
                    projectStackId: id,
                  });
                }}
                key={id}
                variant="menu"
              >
                {label}
              </Button>
            );
          })}
        </ScrollArea>
      ) : stacks.length ? (
        <div className={styles.empty}>Stack doesn't exist...</div>
      ) : (
        <div className={styles.spacer} />
      )}
      {addStackCapability?.capable && (
        <DropdownMenuItem
          onSelect={() => {
            openStackManagementModal({
              projects: [project],
            });
          }}
        >
          <Button
            className={styles.addStack}
            iconBefore={<Add />}
            size="sm"
            variant="outlined"
          >
            New Stack
          </Button>
        </DropdownMenuItem>
      )}
    </div>
  );
};

export const ProjectStackListItem = ({
  className,
  stack,
}: { className?: string; stack: ProjectStackDTO }) => {
  const { open: openStackManagementModal } = useStackManagementModal();
  const { open: openDeleteStackModal } = useDeleteStackModal();
  return (
    <div
      className={clsx(styles.stackListItem, className)}
      onClick={() => openStackManagementModal({ stack })}
    >
      <div className={styles.header}>
        <DropdownMenu
          align="end"
          triggerContent={
            <Button variant="icon" size="sm">
              <MenuMore color="#B5B7CA" size={20} />
            </Button>
          }
        >
          <DropdownMenuItem
            asChild
            onClick={(e) => {
              e.stopPropagation();
              openDeleteStackModal(stack.id);
            }}
          >
            <Button variant="menu" iconBefore={<Remove size={20} />}>
              Empty & Delete Stack
            </Button>
          </DropdownMenuItem>
        </DropdownMenu>
      </div>
      <div className={styles.content}>
        <h4>{stack.label}</h4>
        <hr />
        <span>
          {stack.projectCount} space{pluralize(stack.projectCount !== 1)}
        </span>
      </div>
    </div>
  );
};
