import clsx from 'clsx';
import type { MediaGroupDTO } from '@spaceduck/api';
import { Icon24 } from '@spaceduck/icons';
import { type KeyboardEvent, useCallback, useMemo, useState } from 'react';

import { useMediaGroupDetail } from '@api/mediaGroup';
import TagInput from '@components/TagInput';
import type { useCheckMediaGroupUpdated } from '@hooks/useCheckMediaGroupUpdated';
import { useCreateTags } from '@hooks/useCreateTags';

import Button from '@ui/Button';
import Card from '@ui/Card';
import ContextMenu from '@ui/ContextMenu';
import { useMediaGroupContextMenu } from './MediaGroupActionMenu';
import styles from './MediaGroupCard.module.scss';

const { Close } = Icon24;

type MediaGroupCardProps = {
  className?: string;
  selected?: boolean;
  onSelected?: (selected: boolean) => void;
  mediaGroup: MediaGroupDTO;
  onSearchClick?: (id: string) => void;
  updatedMediaGroupMap: ReturnType<typeof useCheckMediaGroupUpdated>;
  inBulkSelectMode: boolean;
  onShowSimilar?: (id: string) => void;
  hideVisual?: boolean;
};

export default function MediaGroupCard({
  className,
  selected,
  onSelected,
  mediaGroup,
  onSearchClick,
  updatedMediaGroupMap,
  inBulkSelectMode,
  onShowSimilar,
  hideVisual,
}: MediaGroupCardProps) {
  const mg = updatedMediaGroupMap[mediaGroup.id] || mediaGroup;
  const mediaGroupId = mediaGroup.id;

  const [showTagCreation, setShowTagCreation] = useState(false);

  const handleShowSimilar = useMemo(() => {
    if (!onShowSimilar) {
      return undefined;
    }
    return () => onShowSimilar(mediaGroupId);
  }, [onShowSimilar, mediaGroupId]);

  const handleTagCreateClick = useCallback(() => {
    setShowTagCreation(true);
  }, []);

  const contextMenu = useMediaGroupContextMenu({
    mediaGroup,
    onTagCreateClick: handleTagCreateClick,
    onShowSimilar: handleShowSimilar,
  });

  const handleClick = useCallback(() => {
    onSearchClick?.(mediaGroupId);
  }, [onSearchClick, mediaGroupId]);

  const handleTagInputClose = useCallback(() => setShowTagCreation(false), []);

  return (
    <ContextMenu
      items={contextMenu}
      disabled={inBulkSelectMode}
      className={styles.contextMenu}
    >
      <Card
        key={mg.id}
        mediaGroup={mg}
        onClick={handleClick}
        selected={selected}
        onSelected={onSelected}
        contextMenu={contextMenu}
        className={clsx(className, styles.card)}
        hideVisual={hideVisual}
      />
      {showTagCreation && (
        <CreateTagInput mediaGroupId={mediaGroupId} close={handleTagInputClose} />
      )}
    </ContextMenu>
  );
}

const CreateTagInput = ({
  close,
  mediaGroupId,
}: {
  close: () => void;
  mediaGroupId: string;
}) => {
  const { data: mediaGroupData } = useMediaGroupDetail(mediaGroupId);
  const { addTag } = useCreateTags(mediaGroupData?.mediaGroup ?? null, true);
  const onKeyDown = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        ev.preventDefault();
        ev.stopPropagation();
        close();
      }
    },
    [close]
  );

  return (
    <div className={styles.createTagInput} onKeyDown={onKeyDown}>
      <Button
        isSquare
        size="xs"
        type="button"
        variant="outlined"
        className={styles.closeButton}
        onClick={close}
      >
        <Close size={12} />
      </Button>
      <TagInput className={styles.tagInput} onAddTag={addTag} autoFocus />
    </div>
  );
};
