import type { ReactNode } from 'react';
import clsx from 'clsx';

import styles from './RadioButton.module.scss';

type RadioButtonProps = {
  children?: ReactNode;
  className?: string;
  isActive?: boolean;
  name: string;
  onSelect: () => void;
  selected: string;
  value: string;
};

export const RadioButton = ({
  children,
  className,
  isActive = false,
  name,
  onSelect,
  selected,
  value,
}: RadioButtonProps) => {
  return (
    <label className={clsx(styles.radioButton, isActive && styles.active, className)}>
      <div className={styles.radioWrapper}>
        <input
          checked={value === selected}
          name={name}
          onChange={(ev) => {
            if (ev.currentTarget.checked) {
              onSelect();
            }
          }}
          type="radio"
          value={value}
        />
        <span className={styles.fauxRadio} />
      </div>
      <span>{children ?? value}</span>
    </label>
  );
};
