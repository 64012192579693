import { useMemo } from 'react';

import { Icon24 } from '@spaceduck/icons';
import { useListMediaGroups } from '@api/mediaGroup';
import MediaGroupCard from '@/components/MediaGroupCard';
import { Carousel, type CarouselOptions } from '@/components/Carousel';

import styles from './StarredMediaGroupCarousel.module.scss';
import { noop } from 'lodash';

const { Pin } = Icon24;

const STARRED_MEDIA_GROUPS_CAROUSEL_OPTIONS: CarouselOptions = {
  align: 'start',
  skipSnaps: true,
};

export type StarredMediaGroupCarouselProps = {
  workspaceId: string;
  projectId: string | null;
};

export const StarredMediaGroupCarousel = ({
  workspaceId,
  projectId,
}: StarredMediaGroupCarouselProps) => {
  const { data, status } = useListMediaGroups(workspaceId, {
    isStarred: true,
    project: projectId ? [projectId] : undefined,
    excludeProjectLibraries: projectId === null,
  });

  const mediaGroups = useMemo(() => {
    if (status !== 'success') {
      return [];
    }
    return data.pages.flatMap((page) => page.mediaGroups);
  }, [status, data]);

  if (status === 'pending') {
    return null;
  }

  if (status === 'error') {
    return (
      <div className={styles.wrapper}>
        <div className={styles.error}>
          <Pin />
          <div>Could not load pinned items</div>
          <div>Refresh the page to try again</div>
        </div>
      </div>
    );
  }

  if (mediaGroups.length === 0) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Carousel
        className={styles.pinCarousel}
        options={STARRED_MEDIA_GROUPS_CAROUSEL_OPTIONS}
        containerClassName={styles.container}
      >
        {mediaGroups.map((mediaGroup) => (
          <div key={mediaGroup.id} className={styles.slide}>
            <MediaGroupCard
              mediaGroup={mediaGroup}
              updatedMediaGroupMap={{}}
              onSearchClick={noop}
              selected={false}
              inBulkSelectMode={false}
              hideVisual
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};
