import type { z } from 'zod';
import { useSyncExternalStore } from 'react';

import { safeParse } from '@utils/json';

type CreateLocalStorageOptions<S extends z.ZodTypeAny> = {
  key: string;
  initial: z.infer<S>;
  schema: S;
  serialize?: (value: z.infer<S>) => string;
};

type UseLocalTransform<T> = {
  value: T;
  setValue: (value: T | ((value: T) => T)) => void;
  clearValue: () => void;
};

const isSetter = <T>(value_or_fn: T | ((value: T) => T)): value_or_fn is T =>
  typeof value_or_fn === 'function';

export const createLocalStorageHook = <S extends z.ZodTypeAny = z.ZodNever>({
  key,
  initial,
  schema,
  serialize = JSON.stringify,
}: CreateLocalStorageOptions<S>) => {
  type T = z.infer<S>;

  const subscribeEmitter = new EventTarget();

  const triggerUpdate = () => {
    // storage events do not fire in the tab that they are initiated from, so
    //  we need to trigger it ourselves.
    subscribeEmitter.dispatchEvent(
      new CustomEvent('localStorageUpdate', { detail: { key } })
    );
  };

  const subscribe = (callback: () => void) => {
    const storageEventHandler = (event: StorageEvent) => {
      if (event.key === key) {
        callback();
      }
    };
    const customEventHandler = (event: CustomEventInit<{ key: string }>) => {
      if (event.detail?.key === key) {
        callback();
      }
    };
    window.addEventListener('storage', storageEventHandler);
    subscribeEmitter.addEventListener('localStorageUpdate', customEventHandler);
    return () => {
      window.removeEventListener('storage', storageEventHandler);
      subscribeEmitter.removeEventListener('localStorageUpdate', customEventHandler);
    };
  };

  let previous: { serialized: string; value: T } | null = null;

  const snapshot = (): T => {
    const serialized = window.localStorage.getItem(key);

    if (serialized === null) {
      return initial;
    }

    if (previous?.serialized === serialized) {
      return previous.value;
    }

    const jsonResult = safeParse(serialized);

    if (!jsonResult.success) {
      console.warn('Invalid JSON stored in localStorage', {
        key,
        serialized,
        error: jsonResult.error,
      });
      return initial;
    }

    const parseResult = schema.safeParse(jsonResult.data);

    if (!parseResult.success) {
      console.warn('Unknown schema stored in localStorage', {
        key,
        serialized,
        error: parseResult.error,
      });
      return initial;
    }

    previous = { serialized, value: parseResult.data };

    return parseResult.data;
  };

  const setValue: UseLocalTransform<T>['setValue'] = (value_or_fn) => {
    const value = isSetter(value_or_fn) ? value_or_fn(snapshot()) : value_or_fn;
    const newValue = serialize(value);
    window.localStorage.setItem(key, newValue);
    triggerUpdate();
  };

  const clearValue = () => {
    window.localStorage.removeItem(key);
    triggerUpdate();
  };

  return (): UseLocalTransform<T> => {
    const value = useSyncExternalStore(subscribe, snapshot);
    return { value, setValue, clearValue };
  };
};
