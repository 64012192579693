import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router';

const WorkspaceLayout = () => {
  const { workspaceId } = useParams();
  const setFocusedWorkspaceId = useWorkspaceStore(
    (state) => state.setFocusedWorkspaceId
  );

  useEffect(() => {
    setFocusedWorkspaceId(workspaceId ?? null);
  }, [workspaceId]);

  return <Outlet />;
};

export default WorkspaceLayout;
