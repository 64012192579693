import type { ReactNode } from 'react';

import styles from './Box.module.scss';
import clsx from 'clsx';

export default function Box({
  children,
  className,
}: {
  children?: ReactNode;
  className?: string;
}) {
  return <div className={clsx(styles.box, className)}>{children}</div>;
}
