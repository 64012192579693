import { useCallback, useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { useShallow } from 'zustand/shallow';

import {
  type MediaGroupFiltersSchema,
  type SearchSuggestionDTO,
  mediaGroupSearchFilterLabels,
} from '@spaceduck/api';

import { trackEvent } from '@lib/analytics/google';
import { useSearchStore } from '@stores/useSearchStore';

type SuggestionFilterSearchQuery = {
  exclude: boolean;
  fromSuggestion: SearchSuggestionDTO;
};

type DateSearchQuery = {
  filter: 'date';
  maxDatetime: Date;
  minDatetime: Date;
};
type SimilarToSearchQuery = {
  filter: 'similarTo';
  mediaGroupId: string;
};

export type SearchQuery =
  | string
  | SuggestionFilterSearchQuery
  | DateSearchQuery
  | SimilarToSearchQuery;

export const isDateSearchQuery = (value: SearchQuery): value is DateSearchQuery => {
  return typeof value !== 'string' && 'filter' in value && value.filter === 'date';
};
export const isFromSuggestionSearchQuery = (
  value: SearchQuery
): value is SuggestionFilterSearchQuery => {
  return typeof value !== 'string' && 'fromSuggestion' in value;
};
export const isSimilarToSearchQuery = (
  value: SearchQuery
): value is SimilarToSearchQuery => {
  return typeof value !== 'string' && 'filter' in value && value.filter === 'similarTo';
};

const searchQueryToStringEditable = (searchQuery: SearchQuery): string => {
  if (isFromSuggestionSearchQuery(searchQuery)) {
    return `${searchQuery.exclude ? '-' : ''}${mediaGroupSearchFilterLabels[searchQuery.fromSuggestion.filter]}:${
      searchQuery.fromSuggestion.label
    }`;
  }
  if (isDateSearchQuery(searchQuery)) {
    return 'date:';
  }
  if (isSimilarToSearchQuery(searchQuery)) {
    return '';
  }
  return searchQuery;
};

const filterSearchQueriesToFilters = (
  searchQueries: Array<SearchQuery>
): MediaGroupFiltersSchema => {
  const filters = searchQueries.reduce((filters: MediaGroupFiltersSchema, current) => {
    if (typeof current === 'string') {
      return filters;
    }
    if (isDateSearchQuery(current)) {
      filters.maxDatetime = current.maxDatetime;
      filters.minDatetime = current.minDatetime;
      return filters;
    }

    if (isSimilarToSearchQuery(current)) {
      filters.similarTo = current.mediaGroupId;
      return filters;
    }

    if (current.fromSuggestion.filter === 'status') {
      const filterKey =
        `${current.fromSuggestion.filter}${current.exclude ? 'Not' : ''}` as const;
      const currentFilter = filters[filterKey] || [];
      currentFilter.push(current.fromSuggestion.id);
      filters[filterKey] = currentFilter;
      return filters;
    }

    const filterKey =
      `${current.fromSuggestion.filter}${current.exclude ? 'Not' : ''}` as const;
    const currentFilter = filters[filterKey] || [];

    if (
      current.fromSuggestion.filter === 'tag' ||
      current.fromSuggestion.filter === 'title'
    ) {
      currentFilter.push(current.fromSuggestion.label);
      filters[filterKey] = currentFilter;
    } else {
      currentFilter.push(current.fromSuggestion.id);
      filters[filterKey] = currentFilter;
    }

    return filters;
  }, {});

  return filters;
};

export const useSearch = () => {
  const {
    isLoading,
    searchElementRef,
    searchInputValue,
    searchQueries,
    debouncedSearchValue,
    setIsLoading,
    setSearchElementRef,
    setSearchInputValue,
    setSearchQueries,
    setShowAltPlaceholder,
    setDebouncedSearchValue,
    showAltPlaceholder,
    excludeProjectLibraries,
    setExcludeProjectLibraries,
  } = useSearchStore(
    useShallow((state) => ({
      isLoading: state.isLoading,
      searchElementRef: state.searchElementRef,
      searchInputValue: state.searchInputValue,
      searchQueries: state.searchQueries,
      debouncedSearchValue: state.debouncedSearchValue,
      setIsLoading: state.setIsLoading,
      setSearchElementRef: state.setSearchElementRef,
      setSearchInputValue: state.setSearchInputValue,
      setSearchQueries: state.setSearchQueries,
      setShowAltPlaceholder: state.setShowAltPlaceholder,
      setDebouncedSearchValue: state.setDebouncedSearchValue,
      showAltPlaceholder: state.showAltPlaceholder,
      excludeProjectLibraries: state.excludeProjectLibraries,
      setExcludeProjectLibraries: state.setExcludeProjectLibraries,
    }))
  );

  const removeSearchQuery = useCallback(
    (idx: number) => {
      setSearchQueries((searchQueries) =>
        searchQueries.filter((_, _idx) => idx !== _idx)
      );
    },
    [setSearchQueries]
  );

  const handleKeyDown = useCallback(
    (ev: KeyboardEvent) => {
      const currentTarget = ev?.currentTarget as HTMLInputElement;
      if (ev.key === 'Backspace') {
        const lastListItem = searchQueries.at(-1);
        if (!currentTarget?.value.length && lastListItem !== undefined) {
          ev.preventDefault();
          setSearchQueries(searchQueries.slice(0, -1));
          setSearchInputValue(searchQueryToStringEditable(lastListItem));
        }
      } else if (ev.key === 'Enter') {
        ev.preventDefault();
        if (searchInputValue) {
          setSearchQueries((searchQueries) => [
            ...searchQueries,
            searchInputValue.trim(),
          ]);
          setSearchInputValue('');
        }
      }
    },
    [searchQueries, setSearchQueries, setSearchInputValue, searchInputValue]
  );

  const clearSearch = useCallback(() => {
    setSearchQueries([]);
    setSearchInputValue('');
  }, [setSearchQueries, setSearchInputValue]);

  const resetSearch = useCallback(() => {
    clearSearch();
    setDebouncedSearchValue(undefined);
    setExcludeProjectLibraries(false);
  }, [clearSearch, setDebouncedSearchValue, setExcludeProjectLibraries]);

  const handleFocus = useCallback(() => {
    if (!(searchQueries.length && searchInputValue)) {
      setShowAltPlaceholder(true);
    }
  }, [searchQueries, searchInputValue, setShowAltPlaceholder]);

  const handleBlur = useCallback(() => {
    setShowAltPlaceholder(false);
  }, [setShowAltPlaceholder]);

  const debouncedSearch = useCallback(
    debounce(async (value: string) => {
      setDebouncedSearchValue(value);
      if (value) {
        trackEvent('search', { search_term: value });
      }
    }, 150),
    []
  );

  const handleInputUpdate = useCallback(
    async (value: string) => {
      setSearchInputValue(value);
      const searchPayload = [
        ...searchQueries.filter((q): q is string => typeof q === 'string'),
      ];

      if (value) searchPayload.push(value);
      debouncedSearch(searchPayload.join(' '));
    },
    [searchQueries]
  );

  const mediaGroupFilters = useMemo(
    () => filterSearchQueriesToFilters(searchQueries),
    [searchQueries]
  );

  const updateSearchInput = useCallback(
    async (newSearchInputValue: string) => {
      if (searchElementRef.current) {
        searchElementRef.current.value = newSearchInputValue;
      }
      setSearchQueries([]);
      handleInputUpdate(newSearchInputValue);
    },
    [setSearchQueries, handleInputUpdate]
  );

  const handleSearchClick = useCallback((mediaGroupId: string) => {
    trackEvent('select_content', {
      content_type: 'mediaGroup',
      content_id: mediaGroupId,
    });
  }, []);

  const setSimilarToFilter = useCallback(
    (mediaGroupId: string | null) => {
      setSearchQueries((searchQueries) => {
        const newQueries = searchQueries.filter(
          (query) => !isSimilarToSearchQuery(query)
        );
        if (mediaGroupId) {
          newQueries.unshift({ filter: 'similarTo', mediaGroupId });
        }
        return newQueries;
      });
    },
    [setSearchQueries]
  );

  const hasQuery =
    !!debouncedSearchValue ||
    Object.keys(mediaGroupFilters).length > 0 ||
    searchQueries.length > 0;

  return {
    hasQuery,
    isLoading,
    setIsLoading,
    debouncedSearchValue,
    handleSearchClick,
    handleBlur,
    handleFocus,
    handleKeyDown,
    removeSearchQuery,
    searchElementRef,
    setSearchElementRef,
    searchInputValue,
    setSearchInputValue,
    setSearchQueries,
    searchQueries,
    showAltPlaceholder,
    updateSearchInput,
    mediaGroupFilters,
    excludeProjectLibraries,
    setExcludeProjectLibraries,
    handleInputUpdate,
    clearSearch,
    resetSearch,
    setSimilarToFilter,
  };
};

export const useLocalSearch = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchQueries, setSearchQueries] = useState(Array<SearchQuery>);
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string | undefined>(
    undefined
  );
  const searchElementRef = useRef<HTMLInputElement | null>(null);
  const setSearchElementRef = (value: { current: HTMLInputElement | null }) => {
    searchElementRef.current = value.current;
  };
  const [showAltPlaceholder, setShowAltPlaceholder] = useState(false);
  const [excludeProjectLibraries, setExcludeProjectLibraries] = useState(false);

  const removeSearchQuery = useCallback((idx: number) => {
    setSearchQueries((searchQueries) =>
      searchQueries.filter((_, _idx) => idx !== _idx)
    );
  }, []);

  const handleKeyDown = useCallback(
    (ev: KeyboardEvent) => {
      const currentTarget = ev?.currentTarget as HTMLInputElement;
      if (ev.key === 'Backspace') {
        const lastListItem = searchQueries.at(-1);
        if (!currentTarget?.value.length && lastListItem !== undefined) {
          ev.preventDefault();
          setSearchQueries(searchQueries.slice(0, -1));
          setSearchInputValue(searchQueryToStringEditable(lastListItem));
        }
      } else if (ev.key === 'Enter') {
        ev.preventDefault();
        if (searchInputValue) {
          setSearchQueries((searchQueries) => [
            ...searchQueries,
            searchInputValue.trim(),
          ]);
          setSearchInputValue('');
        }
      }
    },
    [searchInputValue, searchQueries]
  );

  const clearSearch = useCallback(() => {
    setSearchQueries([]);
    setSearchInputValue('');
  }, []);

  const resetSearch = useCallback(() => {
    clearSearch();
    setDebouncedSearchValue(undefined);
    setExcludeProjectLibraries(false);
  }, [clearSearch]);

  const handleFocus = useCallback(() => {
    if (!(searchQueries.length && searchInputValue)) {
      setShowAltPlaceholder(true);
    }
  }, [searchQueries, searchInputValue]);

  const handleBlur = useCallback(() => {
    setShowAltPlaceholder(false);
  }, []);

  const debouncedSearch = useCallback(
    debounce(async (value: string) => {
      setDebouncedSearchValue(value);
      if (value) {
        trackEvent('search', { search_term: value });
      }
    }, 150),
    []
  );

  const handleInputUpdate = useCallback(
    async (value: string) => {
      setSearchInputValue(value);
      const searchPayload = [
        ...searchQueries.filter((q): q is string => typeof q === 'string'),
      ];

      if (value) searchPayload.push(value);
      debouncedSearch(searchPayload.join(' '));
    },
    [searchQueries]
  );

  const mediaGroupFilters = useMemo(
    () => filterSearchQueriesToFilters(searchQueries),
    [searchQueries]
  );

  const updateSearchInput = async (newSearchInputValue: string) => {
    if (searchElementRef.current) {
      searchElementRef.current.value = newSearchInputValue;
    }
    setSearchQueries([]);
    handleInputUpdate(newSearchInputValue);
  };

  const handleSearchClick = useCallback((mediaGroupId: string) => {
    trackEvent('select_content', {
      content_type: 'mediaGroup',
      content_id: mediaGroupId,
    });
  }, []);

  const setSimilarToFilter = useCallback((mediaGroupId: string | null) => {
    setSearchQueries((searchQueries) => {
      const newQueries = searchQueries.filter(
        (query) => !isSimilarToSearchQuery(query)
      );
      if (mediaGroupId) {
        newQueries.unshift({ filter: 'similarTo', mediaGroupId });
      }
      return newQueries;
    });
  }, []);

  const hasQuery =
    !!debouncedSearchValue ||
    Object.keys(mediaGroupFilters).length > 0 ||
    searchQueries.length > 0;

  return {
    hasQuery,
    isLoading,
    setIsLoading,
    debouncedSearchValue,
    handleSearchClick,
    handleBlur,
    handleFocus,
    handleKeyDown,
    removeSearchQuery,
    searchElementRef,
    setSearchElementRef,
    searchInputValue,
    setSearchInputValue,
    setSearchQueries,
    searchQueries,
    showAltPlaceholder,
    updateSearchInput,
    mediaGroupFilters,
    excludeProjectLibraries,
    setExcludeProjectLibraries,
    handleInputUpdate,
    clearSearch,
    resetSearch,
    setSimilarToFilter,
  };
};
