import { Navigate, useLocation, useNavigate, useParams } from 'react-router';

import { useSession } from '@hooks/useAuth';
import useWorkspaceId from '@/hooks/useWorkspaceId';
import { useGetSubscriptionInfo } from '@/api/billing';
import { urlFor } from '@/urls';
import { useWorkspace } from '@/api/workspace';
import { isCapable } from '@/api/util';
import Spinner from './Spinner';
import styles from './ActiveSubscriptionWrapper.module.scss';
import { useEffect } from 'react';

export default function ActiveSubscriptionWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const session = useSession();
  const workspaceId = useWorkspaceId();
  const { workspaceId: paramsWorkspaceId } = useParams();
  const { data: workspaceData } = useWorkspace(paramsWorkspaceId || workspaceId);
  const { data: subscriptionData } = useGetSubscriptionInfo(
    paramsWorkspaceId || workspaceId
  );
  const location = useLocation();
  const encodedUrl = encodeURIComponent(window.location.href) || '/';

  const navigate = useNavigate();

  useEffect(() => {
    if (workspaceId === null) {
      navigate(urlFor('newUserOnboarding'));
      return;
    }
  }, [workspaceId]);

  if (!subscriptionData || !workspaceData) {
    return <Spinner className={styles.loading} />;
  }
  const { capabilities } = workspaceData.workspace;
  const canSubscribe = isCapable('workspaceSubscribe', capabilities).capable;
  if (session?.kind !== 'authenticated') {
    return (
      <Navigate to={`/login?next=${encodedUrl}`} state={{ from: location }} replace />
    );
  }

  if (subscriptionData.active) {
    return children;
  }

  if (subscriptionData.transition && canSubscribe) {
    return (
      <Navigate
        to={urlFor('workspaceOnboarding', {
          workspaceId: workspaceData.workspace.id,
        })}
        state={{ from: location }}
        replace
      />
    );
  }

  return (
    <Navigate
      to={urlFor('workspaceExpired', { workspaceId: workspaceData.workspace.id })}
      state={{ from: location }}
      replace
    />
  );
}
