import clsx from 'clsx';
import useEmblaCarousel from 'embla-carousel-react';
import type { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel';
import { type PropsWithChildren, useEffect, useState } from 'react';

import styles from './Carousel.module.scss';
import { throttle } from 'lodash';

const START_BREAKPOINT = 1e-4;
const END_BREAKPOINT = 1 - START_BREAKPOINT;

export type CarouselOptions = EmblaOptionsType;
export type CarouselProps = PropsWithChildren<{
  className?: string;
  containerClassName?: string;
  options?: CarouselOptions;
}>;
export const Carousel = ({
  className,
  containerClassName,
  children,
  options,
}: CarouselProps) => {
  const [showStartShadow, setShowStartShadow] = useState(false);
  const [showEndShadow, setShowEndShadow] = useState(false);
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  useEffect(() => {
    const immediate = (emblaApi: EmblaCarouselType) => {
      const scrollProgress = emblaApi.scrollProgress();
      setShowStartShadow(scrollProgress >= START_BREAKPOINT);
      setShowEndShadow(scrollProgress <= END_BREAKPOINT);
    };
    const throttled = throttle(immediate, 150);

    if (emblaApi) {
      emblaApi.on('scroll', throttled);
      emblaApi.on('settle', immediate);
      return () => {
        emblaApi.off('scroll', throttled);
        emblaApi.off('settle', immediate);
      };
    }
  }, [emblaApi]);

  return (
    <div className={clsx(styles.carouselWrapper, className)}>
      <div className={clsx(styles.startShadow, !showStartShadow && styles.hidden)} />
      <div className={styles.carousel} ref={emblaRef}>
        <div className={clsx(styles.carouselContainer, containerClassName)}>
          {children}
        </div>
      </div>
      <div className={clsx(styles.endShadow, !showEndShadow && styles.hidden)} />
    </div>
  );
};
