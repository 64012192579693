import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="1.5"
        d="m3.34 9.173 10.697-5.97M10.827 16.66l5.97-10.697M12.5 1.667 18.333 7.5M7.083 12.917l-5.416 5.416M1.667 7.5 12.5 18.333"
      />
    </svg>
  );
}
