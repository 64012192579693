import Button, { type ButtonProps } from '@ui/Button';
import clsx from 'clsx';

import styles from './ContinueButton.module.scss';

export const ContinueButton = (props: ButtonProps) => {
  const { className, children, ...buttonProps } = props;

  return (
    <Button
      className={clsx(styles.continueButton, className)}
      size="lg"
      type="button"
      variant="primary"
      {...buttonProps}
    >
      {children ?? 'Continue'}
    </Button>
  );
};
