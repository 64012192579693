import {
  addWritingResource,
  type AiModel,
  createChatSession,
  createTemporaryFile,
  createTemporaryLink,
  getChatSession,
  getChatSessionHistory,
  getChatTemporaryFile,
  getChatTemporaryLink,
  getMediaGroupAiSettings,
  getWritingAssistance,
  type MediaGroupAiSettingsPatch,
  patchMediagroupAiSettings,
  queryChatSession,
  removeChatSession,
  removeWritingResource,
  triggerMediaGroupProcessing,
  type WritingAssistanceSchema,
} from '@spaceduck/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryDisabledError } from './errors';
import { exponentialBackoff } from '@spaceduck/utils';

export const researchChatKeys = {
  list: (projectId: string | null) => ['chats', 'project', projectId],
  one: (chatId: string | null) => ['chats', chatId],
  temporaryLink: (linkId: string | null) => ['chats', 'tempLinks', 'one', linkId],
  temporaryFile: (fileId: string | null) => ['chats', 'tempFile', 'one', fileId],
};

export const mediaGroupAiSettingsKeys = {
  one: (mediaGroupId: string | null) => ['mediaGroupAiSettings', mediaGroupId],
};
export type SourceType = 'library' | 'space' | 'url';

type UseCreateChatSessionOptions = {
  retry?: number;
  retryInterval?: number;
  retryExponent: number;
};

export const useCreateChatSession = (options?: UseCreateChatSessionOptions) => {
  return useMutation({
    retry: options?.retry ?? 3,
    retryDelay: (failureCount) =>
      exponentialBackoff(
        options?.retryInterval ?? 1000,
        options?.retryExponent ?? 1.05,
        failureCount
      ),
    mutationFn: ({
      projectId,
      model,
      mediaGroupIds,
      links,
      temporaryFiles,
      initialQuery,
    }: {
      projectId: string;
      model: AiModel;
      mediaGroupIds: string[];
      links: string[];
      temporaryFiles: string[];
      initialQuery: string;
    }) =>
      createChatSession(
        projectId,
        model,
        mediaGroupIds,
        links,
        temporaryFiles,
        initialQuery
      ),
  });
};

export const useGetChatSession = (chatId: string | null) => {
  const enabled = !!chatId;
  return useQuery({
    enabled,
    queryKey: researchChatKeys.one(chatId),
    queryFn: () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      return getChatSession(chatId);
    },
  });
};

export const useChatTemporaryLink = (
  linkId: string | null,
  refetchInterval?: number
) => {
  const enabled = !!linkId;
  const query = useQuery({
    enabled,
    queryKey: researchChatKeys.temporaryLink(linkId),
    queryFn: () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      return getChatTemporaryLink(linkId);
    },
    refetchInterval,
  });
  return query;
};
export const useChatTemporaryFile = (
  fileId: string | null,
  refetchInterval?: number
) => {
  const enabled = !!fileId;
  const query = useQuery({
    enabled,
    queryKey: researchChatKeys.temporaryFile(fileId),
    queryFn: () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      return getChatTemporaryFile(fileId);
    },
    refetchInterval,
  });
  return query;
};

export const useGetChatSessionList = (projectId: string | null) => {
  const enabled = !!projectId;
  return useQuery({
    enabled,
    queryKey: researchChatKeys.list(projectId),
    queryFn: () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      return getChatSessionHistory(projectId);
    },
  });
};

export const useRemoveChatSession = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: removeChatSession,
    onSuccess: (result, chatSessionId) => {
      queryClient.invalidateQueries({
        queryKey: researchChatKeys.one(chatSessionId),
      });
      queryClient.invalidateQueries({
        queryKey: researchChatKeys.list(result.projectId),
      });
    },
  });
};

type UseQueryChatSessionOptions = {
  retry?: number;
  retryInterval?: number;
  retryExponent: number;
};

export const useQueryChatSession = (options?: UseQueryChatSessionOptions) => {
  return useMutation({
    retry: options?.retry ?? 3,
    retryDelay: (failureCount) =>
      exponentialBackoff(
        options?.retryInterval ?? 1000,
        options?.retryExponent ?? 1.05,
        failureCount
      ),
    mutationFn: ({
      query,
      chatId,
    }: {
      chatId: string;
      query: string;
    }) => queryChatSession(query, chatId),
  });
};

export const useCreateTemporaryFile = () => {
  return useMutation({
    mutationFn: ({
      assetId,
    }: {
      assetId: string;
    }) => createTemporaryFile(assetId),
  });
};

export const useCreateTemporaryLink = () => {
  return useMutation({
    mutationFn: ({
      url,
    }: {
      url: string;
    }) => createTemporaryLink(url),
  });
};

export const useTriggerMediaGroupProcessing = () => {
  return useMutation({
    mutationFn: triggerMediaGroupProcessing,
  });
};

export const useMediaGroupAiSettings = (mediaGroupId: string | null) => {
  const enabled = !!mediaGroupId;
  return useQuery({
    enabled,
    queryKey: mediaGroupAiSettingsKeys.one(mediaGroupId),
    queryFn: () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      return getMediaGroupAiSettings(mediaGroupId);
    },
  });
};

export const usePatchMediaGroupAiSettings = (mediaGroupId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: MediaGroupAiSettingsPatch) => {
      return patchMediagroupAiSettings(mediaGroupId, patch);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: mediaGroupAiSettingsKeys.one(mediaGroupId),
      });
    },
  });
};

export const useAddResourceMediaGroupAiSettings = (mediaGroupId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (resourceId: string) => {
      return addWritingResource(mediaGroupId, resourceId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: mediaGroupAiSettingsKeys.one(mediaGroupId),
      });
    },
  });
};

export const useRemoveResourceMediaGroupAiSettings = (mediaGroupId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (resourceId: string) => {
      return removeWritingResource(mediaGroupId, resourceId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: mediaGroupAiSettingsKeys.one(mediaGroupId),
      });
    },
  });
};

type UseWritingAssistanceVariables = WritingAssistanceSchema & {
  signal?: AbortSignal;
};

export type UseWritingAssistanceOptions = {
  onError: (error: unknown, variables: UseWritingAssistanceVariables) => void;
};

export const useWritingAssistance = (
  mediaGroupId: string,
  options?: UseWritingAssistanceOptions
) => {
  return useMutation({
    mutationFn: ({ signal, ...body }: UseWritingAssistanceVariables) => {
      return getWritingAssistance(mediaGroupId, body, signal);
    },
    onError: options?.onError,
  });
};
