import {
  memo,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  type Node,
  type NodeProps,
  Position,
  useReactFlow,
  useUpdateNodeInternals,
} from '@xyflow/react';
import clsx from 'clsx';
import { useNavigate } from 'react-router';
import { useShallow } from 'zustand/shallow';

import type { MediaGroupContentType, MediaGroupDetailDTO } from '@spaceduck/api';
import { Icon16 } from '@spaceduck/icons';
import { exists } from '@spaceduck/utils';

import { useDeleteMediaGroup } from '@api/mediaGroup';
import { isCapable } from '@api/util';
import { getUrl } from '@components/detailsModal/DetailsModalSidebar';
import { useSidebarModal } from '@components/detailsModal/sidebar/SidebarModal';
import { ContentType } from '@components/icons';
import { useBackgroundLocation } from '@hooks/useBackgroundLocation';
import useDeleteMediaGroupConfirmModal from '@hooks/useDeleteMediaGroupConformModal';
import { useMetaKey } from '@hooks/useMetaKey';
import { useNavigateWithState } from '@hooks/useNavigateWithState';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import Button from '@ui/Button';
import ContextMenu, { type ContextMenuItemProps } from '@ui/ContextMenu';
import { RecursiveDropdownMenu } from '@ui/DropdownMenu';
import Spinner from '@ui/Spinner';
import { copyTextToClipboard } from '@utils/copyToClipboard';
import { absoluteUrlFor, urlFor } from '@/urls';
import { BoardStoreContext } from '../context/boardContext';
import { useNodes } from '../hooks/useNodes';
import type { BaseNodeType } from '../types/board';
import { type NodeBorderColor, nodeBorderColors } from '../types/colors';
import ColorSelector from './ColorSelector';
import Handle from './Handle';
import Resizer from './Resizer';
import styles from './FlowCard.module.scss';
import { useIsInWebViewRoute } from '@/hooks/useIsWebView';
import { DetailsModalTooltip } from '../../comments/DetailsModalTooltip';

type DocumentHeaderProps = {
  children: ReactNode;
  color?: NodeBorderColor | string;
  contentType?: MediaGroupContentType;
  contextMenu: ContextMenuItemProps[];
  expanded: boolean;
  openInNewTab?: () => void;
  openInfoPopup?: () => void;
  openInSidebar?: () => void;
  toggleExpanded?: () => void;
  title: string;
};

const { DownArrow, Info, Open2, OptionsThick, RightArrow, RightDock, Unlink } = Icon16;

const DEFAULT_WIDTH = 400;
const DEFAULT_HEIGHT = 400;

const findRelativePosition = (
  currentNode: Node,
  allNodes: Node[]
): { x: number; y: number } => {
  if (!currentNode?.parentId) {
    return { x: currentNode.position.x, y: currentNode.position.y };
  }

  const parentNode = allNodes.find((n) => n.id === currentNode.parentId);
  if (!parentNode) {
    return { x: currentNode.position.x, y: currentNode.position.y };
  }

  const parentPosition = findRelativePosition(parentNode, allNodes);

  return {
    x: parentPosition.x + currentNode.position.x,
    y: parentPosition.y + currentNode.position.y,
  };
};

type FlowCardComponentProps = NodeProps<BaseNodeType> & {
  children?: React.ReactNode;
  isError: boolean;
  isLoading: boolean;
  mediaGroup?: MediaGroupDetailDTO;
  refetch?: () => void;
};

function FlowCardComponent(props: FlowCardComponentProps) {
  const { mediaGroupId, color, expanded } = props.data;
  const context = useContext(BoardStoreContext);
  const { copyNode, cutNode, deleteNode, duplicateNode, selectedNodes, updateNode } =
    useNodes();
  const { getNode, setNodes } = useReactFlow();

  const isSelected = useMemo(
    () => selectedNodes.includes(props.id),
    [props.id, selectedNodes]
  );

  const node = getNode(props.id);

  const [showResizer, setShowResizer] = useState(false);

  const setColor = useCallback(
    (color: NodeBorderColor) => {
      updateNode(props.id, {
        data: {
          color,
        },
      });
    },
    [props.id, updateNode]
  );

  const toggleExpanded = useCallback(() => {
    updateNode(props.id, {
      data: {
        expanded: !expanded,
      },
    });
  }, [expanded, props.id, updateNode]);

  const mediaGroupUrl = urlFor('mediaGroup', { mediaGroupId });
  const { open: openSidebarModal } = useSidebarModal();
  const navigateWithState = useNavigateWithState();
  const { openDrawer, setQuickViewMediaGroupId } = useDetailsModalStore(
    useShallow((state) => ({
      openDrawer: state.openDrawer,
      setQuickViewMediaGroupId: state.setQuickViewMediaGroupId,
    }))
  );

  const openInNewTab = useCallback(() => {
    window.open(mediaGroupUrl);
  }, [mediaGroupUrl]);

  const openInfoPopup = useCallback(() => {
    openSidebarModal({ mediaGroupId });
  }, [mediaGroupId]);

  const contextMediaGroupId = context?.mediaGroupId;

  const openInSidebar = useCallback(() => {
    if (contextMediaGroupId) {
      const url = getUrl('browseRepository', contextMediaGroupId);
      navigateWithState(url);
      openDrawer();
      setQuickViewMediaGroupId(mediaGroupId);
    }
  }, [contextMediaGroupId, navigateWithState, openDrawer, setQuickViewMediaGroupId]);

  const removeFromFrame = useCallback(() => {
    setNodes((nodes) => {
      return nodes.map((node) => {
        if (selectedNodes.includes(node.id) && node.type !== 'groupNode') {
          const { x, y } = node ? findRelativePosition(node, nodes) : { x: 0, y: 0 };

          return {
            ...node,
            extent: undefined,
            parentId: undefined,
            position: {
              x,
              y,
            },
          };
        }

        return node;
      });
    });
  }, [selectedNodes, setNodes]);

  const copy = useCallback(() => copyNode(props.id), [copyNode, props.id]);
  const cut = useCallback(() => cutNode(props.id), [cutNode, props.id]);
  const deleteSelf = useCallback(() => deleteNode(props.id), [deleteNode, props.id]);
  const duplicate = useCallback(() => {
    duplicateNode(props.id);
    setShowResizer(false);
  }, [duplicateNode, props.id]);

  const contextMenu = useMenuContext({
    color,
    copy,
    cut,
    deleteSelf,
    duplicate,
    expanded,
    isInFrame: !!node?.parentId,
    mediaGroupId,
    mediaGroup: props.mediaGroup,
    openInNewTab,
    openInfoPopup,
    openInSidebar,
    removeFromFrame,
    setColor,
    toggleExpanded,
  });

  const updateNodeInternals = useUpdateNodeInternals();

  useEffect(() => {
    // This updated the handle bounds
    updateNodeInternals(props.id);
  }, [props.id]);

  const handleShowResizer = useCallback(() => setShowResizer(true), []);
  const handleHideResizer = useCallback(() => setShowResizer(false), []);
  const handleShouldResize = useCallback(() => !!expanded, [expanded]);

  const nodeHeight = node?.height;
  const nodeWidth = node?.width;

  return (
    <div
      className="nowheel"
      onMouseOver={handleShowResizer}
      onMouseOut={handleHideResizer}
      onFocus={handleShowResizer}
      onBlur={handleHideResizer}
      style={{
        width: nodeWidth ?? DEFAULT_WIDTH,
        height: expanded ? (nodeHeight ?? DEFAULT_HEIGHT) : 'auto',
      }}
    >
      <Resizer
        isVisible={isSelected}
        minWidth={230}
        minHeight={46}
        shouldResize={handleShouldResize}
        handleClassName={clsx(styles.resizerHandle, !expanded && styles.folded)}
        lineClassName={clsx(styles.resizerLine, !expanded && styles.folded)}
      />
      <ContextMenu contentClassName={styles.menu} items={contextMenu}>
        <div className={styles.nodeWrapper}>
          <Handle
            id={`${props.id}--handle-source-top`}
            type="source"
            position={Position.Top}
            className={clsx(
              styles.handle,
              !isSelected && showResizer && styles.isVisible
            )}
          />
          <Handle
            id={`${props.id}--handle-source-right`}
            type="source"
            position={Position.Right}
            className={clsx(
              styles.handle,
              !isSelected && showResizer && styles.isVisible
            )}
          />
          <Handle
            id={`${props.id}--handle-source-bottom`}
            type="source"
            position={Position.Bottom}
            className={clsx(
              styles.handle,
              !isSelected && showResizer && styles.isVisible
            )}
          />
          <Handle
            id={`${props.id}--handle-source-left`}
            type="source"
            position={Position.Left}
            className={clsx(
              styles.handle,
              !isSelected && showResizer && styles.isVisible
            )}
          />
          <Handle
            id={`${props.id}--handle-target-top`}
            type="target"
            position={Position.Top}
            className={clsx(
              styles.handle,
              !isSelected && showResizer && styles.isVisible
            )}
          />
          <Handle
            id={`${props.id}--handle-target-right`}
            type="target"
            position={Position.Right}
            className={clsx(
              styles.handle,
              !isSelected && showResizer && styles.isVisible
            )}
          />
          <Handle
            id={`${props.id}--handle-target-bottom`}
            type="target"
            position={Position.Bottom}
            className={clsx(
              styles.handle,
              !isSelected && showResizer && styles.isVisible
            )}
          />
          <Handle
            id={`${props.id}--handle-target-left`}
            type="target"
            position={Position.Left}
            className={clsx(
              styles.handle,
              !isSelected && showResizer && styles.isVisible
            )}
          />
          <Content
            color={color}
            contentType={props.mediaGroup?.contentType}
            contextMenu={contextMenu}
            expanded={!!expanded}
            hasMediaGroup={!!props.mediaGroup}
            hasNode={!!node}
            isError={props.isError}
            isLoading={props.isLoading}
            mediaGroupId={mediaGroupId}
            openInNewTab={openInNewTab}
            openInfoPopup={openInfoPopup}
            openInSidebar={openInSidebar}
            refetch={props.refetch}
            title={props.mediaGroup?.label}
            toggleExpanded={toggleExpanded}
          >
            {props.children}
          </Content>
        </div>
      </ContextMenu>
    </div>
  );
}

const DOCUMENT_HEADER_SUBMENU_PROPS = { isPadded: true };

function DocumentHeader({
  children,
  color,
  contentType,
  contextMenu,
  expanded,
  openInNewTab,
  openInfoPopup,
  openInSidebar,
  toggleExpanded,
  title,
}: DocumentHeaderProps) {
  return (
    <>
      <div className={clsx(styles.headerBar, color && styles[color])} />
      <div className={styles.toolbar}>
        <DetailsModalTooltip content={expanded ? 'Fold' : 'Expand'}>
          <Button onClick={toggleExpanded} variant="ghost">
            {expanded ? <DownArrow /> : <RightArrow />}
          </Button>
        </DetailsModalTooltip>
        <div
          className={clsx(styles.toolbarHeading, contentType && styles.hasContentType)}
        >
          {contentType && <ContentType contentType={contentType} size={20} />}
          <span>{title || 'Untitled'}</span>
        </div>
        <div className={styles.toolbarButtons}>
          <DetailsModalTooltip content="Open in new tab">
            <Button onClick={openInNewTab} variant="ghost">
              <Open2 />
            </Button>
          </DetailsModalTooltip>
          <DetailsModalTooltip content="Info">
            <Button onClick={openInfoPopup} variant="ghost">
              <Info />
            </Button>
          </DetailsModalTooltip>
          <DetailsModalTooltip content="Open in sidebar">
            <Button onClick={openInSidebar} variant="ghost">
              <RightDock />
            </Button>
          </DetailsModalTooltip>
          <DetailsModalTooltip content="Options">
            <span>
              <RecursiveDropdownMenu
                className={styles.menu}
                dropdownMenuProps={DOCUMENT_HEADER_SUBMENU_PROPS}
                items={contextMenu}
                isUnstyled
              >
                <Button variant="ghost">
                  <OptionsThick />
                </Button>
              </RecursiveDropdownMenu>
            </span>
          </DetailsModalTooltip>
        </div>
      </div>
      {expanded && children}
    </>
  );
}

const Content = ({
  children,
  color,
  contentType,
  contextMenu,
  expanded,
  hasMediaGroup,
  hasNode,
  isError,
  isLoading,
  openInNewTab,
  openInfoPopup,
  openInSidebar,
  refetch,
  title = '',
  toggleExpanded,
}: {
  children?: React.ReactNode;
  color?: NodeBorderColor;
  contextMenu: ContextMenuItemProps[];
  contentType?: MediaGroupContentType;
  expanded: boolean;
  hasMediaGroup?: boolean;
  hasNode?: boolean;
  isError: boolean;
  isLoading: boolean;
  mediaGroupId: string;
  openInNewTab?: () => void;
  openInfoPopup?: () => void;
  openInSidebar?: () => void;
  refetch?: () => void;
  title?: string;
  toggleExpanded?: () => void;
}) => {
  if (!hasNode) {
    return (
      <div className={styles.centerContainer}>
        <Unlink />
      </div>
    );
  }

  if (isError) {
    return (
      <div className={styles.centerContainer}>
        <Button
          onClick={() => {
            refetch?.();
          }}
          title="Refetch"
          type="button"
          variant="icon"
        >
          <Unlink />
        </Button>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={styles.centerContainer}>
        <Spinner />
      </div>
    );
  }

  if (!hasMediaGroup) {
    return (
      <div className={styles.centerContainer}>
        <Unlink />
      </div>
    );
  }

  return (
    <DocumentHeader
      color={color}
      contentType={contentType}
      contextMenu={contextMenu}
      expanded={!!expanded}
      openInNewTab={openInNewTab}
      openInfoPopup={openInfoPopup}
      openInSidebar={openInSidebar}
      title={title}
      toggleExpanded={toggleExpanded}
    >
      {children}
    </DocumentHeader>
  );
};

const useMenuItem = (fn: () => ContextMenuItemProps | null, context: any[]) =>
  useMemo(fn, context);

const MENU_SEPARATOR: ContextMenuItemProps = {
  content: null,
  isSeparator: true,
};

function useMenuContext({
  color,
  copy,
  cut,
  deleteSelf,
  duplicate,
  expanded,
  isInFrame,
  mediaGroup,
  mediaGroupId,
  openInNewTab,
  openInfoPopup,
  openInSidebar,
  removeFromFrame,
  setColor,
  toggleExpanded,
}: {
  color: string | undefined;
  copy: () => void;
  cut: () => void;
  deleteSelf: () => void;
  duplicate: () => void;
  expanded: boolean;
  isInFrame: boolean;
  mediaGroup?: MediaGroupDetailDTO;
  mediaGroupId: string;
  openInNewTab?: () => void;
  openInfoPopup?: () => void;
  openInSidebar?: () => void;
  removeFromFrame: () => void;
  setColor: (color: NodeBorderColor) => void;
  toggleExpanded?: () => void;
}) {
  const currentLocation = `${location.pathname}${location.search}${location.hash}`;
  const backgroundLocation = useBackgroundLocation();

  const context = useContext(BoardStoreContext);
  const navigate = useNavigate();
  const metaKey = useMetaKey();
  const selectedColor = color;
  const canEdit = !!isCapable('edit', mediaGroup?.userCapabilities).capable;
  const { mutateAsync: deleteMediaGroup } = useDeleteMediaGroup();
  const { getNodes } = useReactFlow();
  const { deleteNodes } = useNodes();
  const openDeleteMediaGroupConfirmModal = useDeleteMediaGroupConfirmModal({
    onConfirm: async (id: string) => {
      // Need to find all instances of
      const nodesToDelete = getNodes().filter((node) => node.data.mediaGroupId === id);
      await deleteNodes({ nodes: nodesToDelete });
      await deleteMediaGroup(id);
      context?.store?.temporal.getState().clear();
    },
  });

  const isInWebViewRoute = useIsInWebViewRoute();

  const menuItemCut = useMenuItem(
    () => ({
      content: 'Cut',
      onClick: cut,
      shortcut: isInWebViewRoute ? undefined : `${metaKey} X`,
    }),
    [cut, metaKey, isInWebViewRoute]
  );

  const menuIconCopy = useMenuItem(
    () => ({
      content: 'Copy',
      onClick: copy,
      shortcut: isInWebViewRoute ? undefined : `${metaKey} C`,
    }),
    [copy, metaKey, isInWebViewRoute]
  );
  const menuItemDuplicate = useMenuItem(
    () => ({
      content: 'Duplicate',
      onClick: duplicate,
      shortcut: isInWebViewRoute ? undefined : `${metaKey} D`,
    }),
    [duplicate, metaKey, isInWebViewRoute]
  );
  const menuItemFromFromBoard = useMenuItem(
    () => ({
      content: 'Remove from board',
      onClick: deleteSelf,
      shortcut: isInWebViewRoute ? undefined : 'Del',
    }),
    [deleteSelf, isInWebViewRoute]
  );

  const menuItemFromFromFrame = useMenuItem(() => {
    if (!isInFrame) {
      return null;
    }
    return {
      content: 'Remove from Frame',
      onClick: removeFromFrame,
    };
  }, [isInFrame, removeFromFrame]);

  const menuItemToggleFold = useMenuItem(
    () => ({
      content: `${expanded ? 'Fold' : 'Expand'}`,
      onClick: toggleExpanded,
    }),
    [expanded, toggleExpanded]
  );

  const menuItemBorderColors = useMenuItem(
    () => ({
      className: styles.noHover,
      content: (
        <div className={styles.colorList}>
          {nodeBorderColors.map((color) => {
            return (
              <ColorSelector
                key={color}
                color={color}
                isActive={color === selectedColor}
                onChange={setColor}
              />
            );
          })}
        </div>
      ),
    }),
    [selectedColor, toggleExpanded, setColor]
  );
  const menuItemOpenInSidebar = useMenuItem(
    () => ({
      content: 'Open in sidebar',
      onClick: openInSidebar,
    }),
    [openInSidebar]
  );
  const menuItemOpenInNewTab = useMenuItem(
    () => ({
      content: 'Open in new tab',
      onClick: openInNewTab,
    }),
    [openInNewTab]
  );

  const menuItemOpenInPopup = useMenuItem(
    () => ({
      content: 'Open in popup',
      onClick: () => {
        navigate(
          urlFor('mediaGroup', {
            mediaGroupId,
          }),
          {
            state: {
              backgroundLocation: [currentLocation, ...backgroundLocation],
            },
          }
        );
      },
    }),
    [navigate, currentLocation, backgroundLocation]
  );

  const menuItemShowInfo = useMenuItem(
    () => ({
      content: 'Show info',
      onClick: openInfoPopup,
    }),
    [openInfoPopup]
  );

  const menuItemCopyLink = useMenuItem(
    () => ({
      content: 'Copy link',
      onClick: () =>
        copyTextToClipboard(
          absoluteUrlFor('mediaGroup', {
            mediaGroupId,
          })
        ),
    }),
    [copyTextToClipboard, mediaGroupId]
  );

  const menuItemDeleteFromWorkspace = useMenuItem(() => {
    if (!canEdit || !mediaGroupId) {
      return null;
    }
    return {
      content: isInWebViewRoute ? 'Delete item' : 'Delete item from workspace',
      onClick: () => openDeleteMediaGroupConfirmModal(mediaGroupId),
    };
  }, [canEdit, mediaGroupId, openDeleteMediaGroupConfirmModal, isInWebViewRoute]);

  return useMemo(() => {
    return [
      menuItemCut,
      menuIconCopy,
      menuItemDuplicate,
      menuItemFromFromBoard,
      menuItemFromFromFrame,
      MENU_SEPARATOR,
      menuItemToggleFold,
      menuItemBorderColors,
      MENU_SEPARATOR,
      menuItemOpenInSidebar,
      menuItemOpenInNewTab,
      menuItemOpenInPopup,
      MENU_SEPARATOR,
      menuItemShowInfo,
      MENU_SEPARATOR,
      menuItemCopyLink,
      MENU_SEPARATOR,
      menuItemDeleteFromWorkspace,
    ].filter(exists);
  }, [
    menuItemCut,
    menuIconCopy,
    menuItemDuplicate,
    menuItemFromFromBoard,
    menuItemFromFromFrame,
    menuItemToggleFold,
    menuItemBorderColors,
    menuItemOpenInSidebar,
    menuItemOpenInNewTab,
    menuItemOpenInPopup,
    menuItemShowInfo,
    menuItemCopyLink,
    menuItemDeleteFromWorkspace,
  ]);
}

export const FlowCard = memo(FlowCardComponent);
