import { useMediaGroupDetail, useSimilarMediaGroups } from '@api/mediaGroup';
import type { MediaGroupContentType, MediaGroupDTO } from '@spaceduck/api';
import { Icon24 } from '@spaceduck/icons';
import clsx from 'clsx';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useShallow } from 'zustand/shallow';

import { urlFor } from '@/urls';
import { ContentType } from '@components/icons';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import Card from '@ui/Card';
import Spinner from '@ui/Spinner';
import Tooltip from '@ui/Tooltip';
import { calcCardHeight } from '@utils/mediaGroup';
import DraggableCard from './DraggableCard';
import ExtendedPreviewCard from './ExtendedPreviewCard';
import { useSidebarModal } from './SidebarModal';
import styles from './SimilarContent.module.scss';
import { pluralize } from '@spaceduck/utils';

const { RepositoryBrowse } = Icon24;

type SimilarMediaGroups = Partial<Record<MediaGroupContentType, MediaGroupDTO[]>>;
const similarMediaGroupsOrder: Record<MediaGroupContentType, MediaGroupContentType[]> =
  {
    document: ['document', 'pdf', 'image', 'video', 'highlight', 'quote', 'file'],
    image: ['image', 'video', 'pdf', 'document', 'file'],
    mixed: ['mixed', 'image', 'video', 'pdf', 'file'],
    video: ['video', 'image'],
    file: ['file'],
    pdf: ['pdf', 'document', 'image', 'highlight', 'quote', 'video', 'file'],
    bookmark: ['bookmark', 'document'],
    article: ['article', 'bookmark', 'document', 'wiki'],
    wiki: ['wiki', 'bookmark', 'document', 'article'],
    social: ['social', 'bookmark'],
    audio: ['audio', 'bookmark', 'video'],
    quote: ['quote', 'highlight', 'document', 'pdf'],
    highlight: ['highlight', 'quote', 'document', 'pdf'],
    board: [
      'image',
      'video',
      'audio',
      'document',
      'pdf',
      'quote',
      'highlight',
      'board',
      'article',
      'wiki',
      'social',
      'bookmark',
      'file',
    ],
  };
const pluralizeContentType = (kind: MediaGroupContentType) => {
  switch (kind) {
    case 'mixed':
      return 'mixed';
    default:
      return kind + pluralize(true);
  }
};

function SimilarSingleContentType({
  contentType,
  mediaGroupsByContentType,
}: {
  contentType: MediaGroupContentType;
  mediaGroupsByContentType: SimilarMediaGroups;
}) {
  const setQuickViewMediaGroupId = useDetailsModalStore(
    (store) => store.setQuickViewMediaGroupId
  );
  const [showAllLinks, setShowAllLinks] = useState(false);
  const topRef = useDetailsModalStore((store) => store.topRef);

  return (
    <div>
      <div className={styles.typeHeader}>
        <span>Related {pluralizeContentType(contentType)}</span>
        <span>•</span>
        <span>{mediaGroupsByContentType[contentType]?.length || 0}</span>
      </div>
      <div className={clsx(styles.documentList, showAllLinks && styles.expanded)}>
        {mediaGroupsByContentType[contentType]?.map((mediaGroup) => {
          const { kind, thumbnail } = mediaGroup;
          const height = calcCardHeight({
            columnWidth: 280,
            isNotes: kind === 'document',
            thumbNailHeight: thumbnail.height,
            thumbNailWidth: thumbnail.width,
          });

          return (
            <Tooltip
              align="start"
              content={
                <div
                  className={styles.previewWrapper}
                  onClick={() => {
                    window.open(
                      urlFor('mediaGroup', {
                        mediaGroupId: mediaGroup.id,
                      })
                    );
                  }}
                  style={{
                    height: `${height ?? 500}px`,
                    overflow: 'hidden',
                    width: '280px',
                  }}
                >
                  <Card className={styles.preview} mediaGroup={mediaGroup} />
                </div>
              }
              delayDuration={100}
              key={mediaGroup.id}
              portalProps={{
                container: topRef?.current,
              }}
              side="left"
              size="medium"
              variant="bare"
            >
              <div>
                <DraggableCard mediaGroup={mediaGroup}>
                  <div
                    className={styles.relatedDocument}
                    onClick={() => {
                      setQuickViewMediaGroupId(mediaGroup.id);
                    }}
                  >
                    <ContentType size={20} contentType={mediaGroup.contentType} />
                    <span className={styles.label}>{mediaGroup.label}</span>
                  </div>
                </DraggableCard>
              </div>
            </Tooltip>
          );
        })}
        <div className={styles.overflowOverlay} onClick={() => setShowAllLinks(true)} />
      </div>
    </div>
  );
}

export default function SimilarContent({
  mediaGroupId,
}: {
  mediaGroupId: string;
}) {
  const quickViewMediaGroupId = useDetailsModalStore(
    (store) => store.quickViewMediaGroupId
  );
  const { data: mediaGroup, isLoading: isLoadingMediaGroup } =
    useMediaGroupDetail(mediaGroupId);
  const { data: similarMediaGroups, isLoading } = useSimilarMediaGroups(
    mediaGroupId,
    mediaGroup?.mediaGroup.contentType
      ? similarMediaGroupsOrder[mediaGroup.mediaGroup.contentType]
      : []
  );
  const mediaGroups = similarMediaGroups?.mediaGroups || [];
  if (isLoading || isLoadingMediaGroup || !mediaGroup) {
    return <Spinner />;
  }
  const mediaGroupsByContentType = mediaGroups.reduce<SimilarMediaGroups>(
    (prev, mediaGroup) => {
      if (!prev[mediaGroup.contentType]) {
        prev[mediaGroup.contentType] = [mediaGroup];
        return prev;
      }
      prev[mediaGroup.contentType]?.push(mediaGroup);
      return prev;
    },
    {}
  );

  if (quickViewMediaGroupId) {
    return <QuickView mediaGroupId={mediaGroupId} />;
  }

  if (mediaGroups.length === 0) {
    return (
      <div className={styles.empty}>
        <RepositoryBrowse size={32} />
        <p>
          No related items are available yet. This space will automatically display
          associated content as it becomes available, providing an opportunity to add
          relevant items or explore similar ones.
        </p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.relatedDocs}>
        {similarMediaGroupsOrder[mediaGroup.mediaGroup.contentType]
          ?.filter((contentType) => mediaGroupsByContentType[contentType])
          .map((contentType) => {
            return (
              <SimilarSingleContentType
                key={contentType}
                contentType={contentType}
                mediaGroupsByContentType={mediaGroupsByContentType}
              />
            );
          })}
      </div>
    </div>
  );
}

const QuickView = ({ mediaGroupId }: { mediaGroupId: string }) => {
  const navigate = useNavigate();
  const {
    quickViewMediaGroupId,
    setQuickViewMediaGroupId,
    setSwapQuickViewMediaGroupId,
  } = useDetailsModalStore(
    useShallow((store) => ({
      quickViewMediaGroupId: store.quickViewMediaGroupId,
      setQuickViewMediaGroupId: store.setQuickViewMediaGroupId,
      setSwapQuickViewMediaGroupId: store.setSwapQuickViewMediaGroupId,
    }))
  );
  const { data, error, isLoading } = useMediaGroupDetail(quickViewMediaGroupId);
  const { open: openSidebarModal } = useSidebarModal();

  if (error) return null;

  if (isLoading) {
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  }

  if (!data) return null;

  const { mediaGroup } = data;

  return (
    <ExtendedPreviewCard
      mediaGroup={mediaGroup}
      onClose={() => setQuickViewMediaGroupId(null)}
      onDelete={() => setQuickViewMediaGroupId(null)}
      onSwapClick={() => {
        setSwapQuickViewMediaGroupId(mediaGroupId);
        navigate(
          urlFor('mediaGroupBrowseRepository', {
            mediaGroupId: mediaGroup.id,
          })
        );
      }}
      onViewInfoClick={() => openSidebarModal({ mediaGroupId: mediaGroup.id })}
    />
  );
};
