import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { urlFor } from '@/urls';
import useWorkspaceId from '@hooks/useWorkspaceId';
import Spinner from '@/components/Spinner';

export default function SpacesRedirectPage() {
  const workspaceId = useWorkspaceId();
  const navigate = useNavigate();

  useEffect(() => {
    if (workspaceId === null) {
      navigate(urlFor('newUserOnboarding'));
      return;
    }
    if (workspaceId) {
      navigate(urlFor('workspaceHome', { workspaceId }), {
        replace: true,
      });
      return;
    }
  }, [workspaceId]);

  return <Spinner />;
}
