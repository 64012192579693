import SettingsSidebarContent from '@layouts/sidebar/SettingsSidebarContent';
import BackButton from '@layouts/sidebar/account/BackButton';
import MainMenu from '@layouts/sidebar/account/Menu';
import TwoColumnLayout from './TwoColumn';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { useEffect } from 'react';

export default function SettingsLayout() {
  const setFocusedWorkspaceId = useWorkspaceStore(
    (state) => state.setFocusedWorkspaceId
  );

  useEffect(() => {
    setFocusedWorkspaceId(null);
  }, []);
  return (
    <TwoColumnLayout>
      <SettingsSidebarContent
        backButton={<BackButton />}
        includeMoreMenu={true}
        menu={<MainMenu />}
      />
    </TwoColumnLayout>
  );
}
