import type { ReactNode } from 'react';

import styles from './Slide.module.scss';

export const Slide = ({
  children,
}: {
  children: ReactNode;
}) => {
  return <div className={styles.slide}>{children}</div>;
};
