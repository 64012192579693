import { type MouseEventHandler, useCallback, useMemo } from 'react';
import { upperFirst } from 'lodash';

import type { SubscriptionInfo } from '@spaceduck/api';
import { Icon24 } from '@spaceduck/icons';
import { pluralize } from '@spaceduck/utils';

import { useGetSubscriptionInfo } from '@api/billing';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { SubscriptionFeatures } from '@pages/common/SubscriptionFeatures';
import Button from '@ui/Button';
import Spinner from '@ui/Spinner';
import { Box, Container, Header, TopNav } from '../common';
import {
  type ManageSubscriptionParams,
  useManageSubscription,
} from './useManageSubscription';
import styles from './BillingAndPlanPage.module.scss';
import { useNavigate } from 'react-router';
import { urlFor } from '@/urls';
import { getDisplayDate } from '@/utils/date';

const { Open } = Icon24;

export function BillingAndPlanPage() {
  const workspaceId = useWorkspaceId();
  const { data: subscriptionInfo } = useGetSubscriptionInfo(workspaceId);
  const { mutateAsync: manageSubscription, isAnyPending } = useManageSubscription();
  const subscriptionIsActive = subscriptionInfo?.active || false;

  return (
    <>
      <TopNav
        title="Manage Billing and Plan"
        currentBreadcrumb="Billing & Plan"
        owner="workspace"
      >
        {workspaceId && subscriptionInfo?.active && (
          <Button
            size="sm"
            type="button"
            variant="outlined"
            onClick={async () => {
              await manageSubscription({ action: 'manage', workspaceId });
            }}
            disabled={isAnyPending}
          >
            Manage subscription
            {isAnyPending ? <Spinner size={16} /> : <Open size={16} />}
          </Button>
        )}
      </TopNav>
      {workspaceId && (
        <Container className={styles.container}>
          <Header>
            <h1>Billing & Plan</h1>
            <p>Manage your workspace billing & plan.</p>
          </Header>
          {subscriptionInfo && (
            <PlanFeatures
              subscriptionIsActive={subscriptionIsActive}
              subscriptionInfo={subscriptionInfo}
              manageSubscription={manageSubscription}
              isAnyPending={isAnyPending}
              workspaceId={workspaceId}
            />
          )}
        </Container>
      )}
    </>
  );
}

const PlanFeatures = ({
  subscriptionIsActive,
  isAnyPending,
  manageSubscription,
  subscriptionInfo,
  workspaceId,
}: {
  isAnyPending: boolean;
  subscriptionIsActive: boolean;
  manageSubscription: (params: ManageSubscriptionParams) => void;
  subscriptionInfo: SubscriptionInfo;
  workspaceId: string;
}) => {
  const { seats, plan, interval, currentPeriodEndTimestamp, source } = subscriptionInfo;
  const navigate = useNavigate();
  const handleManage = useCallback<MouseEventHandler>(
    (event) => {
      event.preventDefault();
      if (subscriptionIsActive && source === 'stripe') {
        manageSubscription({ action: 'manage', workspaceId });
      } else {
        navigate(urlFor('workspaceOnboarding', { workspaceId }));
      }
    },
    [manageSubscription, workspaceId, source]
  );
  const planInterval = interval === 'year' ? 'Annual' : 'Monthly';
  const nextPaymentDate = useMemo(() => {
    if (currentPeriodEndTimestamp === null) {
      return null;
    }
    const date = new Date(currentPeriodEndTimestamp * 1000);
    return getDisplayDate(date);
  }, [currentPeriodEndTimestamp]);

  return (
    <>
      <Box>
        {subscriptionIsActive ? (
          <>
            <h2>{`${upperFirst(plan)} Plan - ${planInterval} (${seats}x Active ${pluralize(seats > 1, 'seats', 'seat')})`}</h2>
            {nextPaymentDate && (
              <p>{`Your next payment is scheduled for ${nextPaymentDate}. Thank you for your support!`}</p>
            )}
          </>
        ) : (
          <>
            <h2>Account inactive</h2>
            <p>
              You are not subscribed. Click the button below to renew your subscription.
            </p>
          </>
        )}
        <SubscriptionFeatures subscriptionIsActive={subscriptionIsActive} />
        <Button
          className={styles.blockButton}
          disabled={isAnyPending}
          onClick={handleManage}
          size="md"
          variant="outlined"
        >
          Manage Subscription
        </Button>
      </Box>
      <p className={styles.secondaryAction}>
        {/* biome-ignore lint/a11y/noBlankTarget: safe link for tracking */}
        <a href="https://spaceduck.com/pricing" target="_blank">
          View pricing page for more details
        </a>
      </p>
    </>
  );
};
