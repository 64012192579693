import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3832_4314)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.083374 2C-0.083374 1.58579 0.252412 1.25 0.666626 1.25H15.3333C15.7475 1.25 16.0833 1.58579 16.0833 2C16.0833 2.41421 15.7475 2.75 15.3333 2.75H0.666626C0.252412 2.75 -0.083374 2.41421 -0.083374 2Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.083374 6C-0.083374 5.58579 0.252412 5.25 0.666626 5.25H8.66663C9.08084 5.25 9.41663 5.58579 9.41663 6C9.41663 6.41421 9.08084 6.75 8.66663 6.75H0.666626C0.252412 6.75 -0.083374 6.41421 -0.083374 6Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.083374 10C-0.083374 9.58579 0.252412 9.25 0.666626 9.25H15.3333C15.7475 9.25 16.0833 9.58579 16.0833 10C16.0833 10.4142 15.7475 10.75 15.3333 10.75H0.666626C0.252412 10.75 -0.083374 10.4142 -0.083374 10Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.083374 14C-0.083374 13.5858 0.252412 13.25 0.666626 13.25H8.66663C9.08084 13.25 9.41663 13.5858 9.41663 14C9.41663 14.4142 9.08084 14.75 8.66663 14.75H0.666626C0.252412 14.75 -0.083374 14.4142 -0.083374 14Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3832_4314">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
