import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3832_4313)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.083374 2C-0.083374 1.58579 0.252412 1.25 0.666626 1.25H15.3333C15.7475 1.25 16.0833 1.58579 16.0833 2C16.0833 2.41421 15.7475 2.75 15.3333 2.75H0.666626C0.252412 2.75 -0.083374 2.41421 -0.083374 2Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.58325 6C6.58325 5.58579 6.91904 5.25 7.33325 5.25H15.3333C15.7475 5.25 16.0833 5.58579 16.0833 6C16.0833 6.41421 15.7475 6.75 15.3333 6.75H7.33325C6.91904 6.75 6.58325 6.41421 6.58325 6Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.083374 10C-0.083374 9.58579 0.252412 9.25 0.666626 9.25H15.3333C15.7475 9.25 16.0833 9.58579 16.0833 10C16.0833 10.4142 15.7475 10.75 15.3333 10.75H0.666626C0.252412 10.75 -0.083374 10.4142 -0.083374 10Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.58325 14C6.58325 13.5858 6.91904 13.25 7.33325 13.25H15.3333C15.7475 13.25 16.0833 13.5858 16.0833 14C16.0833 14.4142 15.7475 14.75 15.3333 14.75H7.33325C6.91904 14.75 6.58325 14.4142 6.58325 14Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3832_4313">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
