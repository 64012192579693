import { Icon24 } from '@spaceduck/icons';

import styles from './SubscriptionFeatures.module.scss';

const { Check, Close } = Icon24;

const features = [
  'Unlimited AI Writing',
  '500MB Upload size',
  'Unlimited AI Assistant',
  '500GB Storage',
  'Unlimited AI Summaries',
  'AI Tagging',
  'Unlimited Items',
  'AI Organization',
  'Unlimited Spaces',
  'AI Search',
];

export const SubscriptionFeatures = ({
  subscriptionIsActive,
}: {
  subscriptionIsActive: boolean;
}) => {
  return (
    <ul className={styles.featureList}>
      {features.map((feature, idx) => {
        return (
          <li key={idx}>
            {subscriptionIsActive ? (
              <Check className={styles.green} />
            ) : (
              <Close className={styles.red} />
            )}
            <span>{feature}</span>
          </li>
        );
      })}
    </ul>
  );
};
