import clsx from 'clsx';
import { Outlet } from 'react-router';

import AuthWrapper from '@components/AuthWrapper';
import styles from './ModalView.module.scss';

export default function ModalViewLayout({
  withAuth,
}: {
  withAuth?: boolean;
}) {
  if (withAuth) {
    return (
      <AuthWrapper>
        <CenterWrapper>
          <Outlet />
        </CenterWrapper>
      </AuthWrapper>
    );
  }

  return (
    <CenterWrapper>
      <Outlet />
    </CenterWrapper>
  );
}

export const CenterWrapper = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <div className={clsx(styles.centerWrapper, className)}>{children}</div>;
};
