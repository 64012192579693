import { useWorkspace } from '@api/workspace';
import useWorkspaceId from '@hooks/useWorkspaceId';
import Homepage from './workspace/Homepage';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { urlFor } from '@/urls';
import Spinner from '@/components/Spinner';

const RedirectToWorkspaceSelector = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(urlFor('userSettingsWorkspaces'));
  }, []);
  return <Spinner />;
};

export default function WorkspacePage() {
  const workspaceId = useWorkspaceId();
  const { data, error: workspaceError } = useWorkspace(workspaceId);

  if (workspaceError) {
    return <RedirectToWorkspaceSelector />;
  }

  if (!data?.workspace) {
    return <Spinner />;
  }

  return <Homepage workspace={data.workspace} />;
}
