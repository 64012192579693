import '@layouts/sidebar/Menu.scss';
import MenuMainNavSection from './MenuMainNavSection';
import MenuProjectsSection from './MenuProjectsSection';
import MenuUserSection from './MenuUserSection';
import SearchSection from './SearchSection';
import styles from './SpaceMenu.module.scss';
import { HelpSection } from './HelpSection';

export default function ProjectMenu() {
  return (
    <div className={styles.spaceMenu}>
      <div className={styles.menu}>
        <MenuUserSection />
        <SearchSection />
        <MenuMainNavSection />
        <Spacer />
        <MenuProjectsSection />
      </div>
      <Spacer />
      <HelpSection />
    </div>
  );
}

const Spacer = () => {
  return <div className={styles.spacer} />;
};
