import type { Editor } from '@tiptap/react';

export type Transformation =
  | 'bold'
  | 'bulletedList'
  | 'codeBlock'
  | 'color'
  | 'heading1'
  | 'heading2'
  | 'heading3'
  | 'heading4'
  | 'heading5'
  | 'heading6'
  | 'highlight'
  | 'inlineCode'
  | 'italic'
  | 'numberedList'
  | 'quote'
  | 'strikethrough'
  | 'text'
  | 'textCenter'
  | 'textLeft'
  | 'textRight'
  | 'todoList'
  | 'underline';

type TransformationOptions = {
  color?: string;
};

export const handleTransformation = (
  editor: Editor,
  transformation: Transformation,
  options?: TransformationOptions
) => {
  switch (transformation) {
    case 'text':
      editor.chain().focus().setParagraph().run();
      return;
    case 'heading1':
      editor.chain().focus().toggleHeading({ level: 1 }).run();
      return;
    case 'heading2':
      editor.chain().focus().toggleHeading({ level: 2 }).run();
      return;
    case 'heading3':
      editor.chain().focus().toggleHeading({ level: 3 }).run();
      return;
    case 'heading4':
      editor.chain().focus().toggleHeading({ level: 4 }).run();
      return;
    case 'heading5':
      editor.chain().focus().toggleHeading({ level: 5 }).run();
      return;
    case 'heading6':
      editor.chain().focus().toggleHeading({ level: 6 }).run();
      return;
    case 'inlineCode':
      editor.chain().focus().toggleCode().run();
      return;
    case 'codeBlock':
      editor.chain().focus().toggleCodeBlock().run();
      return;
    case 'quote':
      editor.chain().focus().toggleBlockquote().run();
      return;
    case 'bold':
      editor.chain().focus().toggleBold().run();
      return;
    case 'strikethrough':
      editor.chain().focus().toggleStrike().run();
      return;
    case 'underline':
      editor.chain().focus().toggleUnderline().run();
      return;
    case 'italic':
      editor.chain().focus().toggleItalic().run();
      return;
    case 'bulletedList':
      editor.chain().focus().toggleBulletList().run();
      return;
    case 'numberedList':
      editor.chain().focus().toggleOrderedList().run();
      return;
    case 'textCenter':
      editor.chain().focus().setTextAlign('center').run();
      return;
    case 'textLeft':
      editor.chain().focus().setTextAlign('left').run();
      return;
    case 'textRight':
      editor.chain().focus().setTextAlign('right').run();
      return;
    case 'todoList':
      editor.chain().focus().toggleTaskList().run();
      return;
    case 'color':
      if (options?.color) {
        if (options.color === 'none') {
          editor.chain().focus().unsetColor().run();
        } else if (editor.isActive('textStyle', { color: options.color })) {
          editor.chain().focus().unsetColor().run();
        } else {
          editor.chain().focus().setColor(options.color).run();
        }
      }
      return;
    case 'highlight':
      if (options?.color) {
        if (options.color === 'none') {
          editor.chain().focus().unsetHighlight().run();
        } else {
          editor.chain().focus().toggleHighlight({ color: options.color }).run();
        }
      }
      return;
    default:
      return;
  }
};
