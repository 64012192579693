import clsx from 'clsx';
import { Outlet } from 'react-router';

import stars from '@assets/img/stars-faded.svg';
import AuthWrapper from '@components/AuthWrapper';
import styles from './Glow.module.scss';

export default function GlowLayout({
  withAuth,
}: {
  withAuth?: boolean;
}) {
  if (withAuth) {
    return (
      <AuthWrapper>
        <OuterSpace>
          <Outlet />
        </OuterSpace>
      </AuthWrapper>
    );
  }

  return (
    <OuterSpace>
      <Outlet />
    </OuterSpace>
  );
}

export const OuterSpace = ({
  children,
  className,
  light = true,
}: {
  children: React.ReactNode;
  className?: string;
  light?: boolean;
}) => {
  return (
    <div className={clsx(styles.world, className)}>
      {light && (
        <div className={styles.lightWrapper}>
          <div className={styles.light} />
        </div>
      )}
      <div className={styles.starsWrapper}>
        <img src={stars} className={styles.stars} width={946} height={586} />
      </div>
      <main>{children}</main>
    </div>
  );
};
