import { type HotkeyCallback, useHotkeys } from 'react-hotkeys-hook';
import type { Keys, OptionsOrDependencyArray } from 'react-hotkeys-hook/dist/types';

import { useIsMac } from './useOs';

export const usePlatformKeys = (
  mac: Keys,
  others: Keys,
  callback: HotkeyCallback,
  options?: OptionsOrDependencyArray,
  dependencies?: OptionsOrDependencyArray
) => {
  const isMac = useIsMac();
  const binding = isMac ? mac : others;
  return useHotkeys(binding, callback, options, dependencies);
};

type NamedHotKeyHook = (
  callback: HotkeyCallback,
  options?: OptionsOrDependencyArray,
  dependencies?: OptionsOrDependencyArray
) => ReturnType<typeof useHotkeys>;

export const useCopyHotkey: NamedHotKeyHook = (...args) =>
  usePlatformKeys('Meta + C', 'Ctrl + C', ...args);
export const useCutHotkey: NamedHotKeyHook = (...args) =>
  usePlatformKeys('Meta + X', 'Ctrl + X', ...args);
export const usePasteHotkey: NamedHotKeyHook = (...args) =>
  usePlatformKeys('Meta + V', 'Ctrl + V', ...args);
export const useUndoHotkey: NamedHotKeyHook = (...args) =>
  usePlatformKeys('Meta + Z', 'Ctrl + Z', ...args);
export const useRedoHotkey: NamedHotKeyHook = (...args) =>
  usePlatformKeys('Meta + Shift + Z', 'Ctrl + Y', ...args);
export const useSelectAllHotkey: NamedHotKeyHook = (...args) =>
  usePlatformKeys('Meta + A', 'Ctrl + A', ...args);
export const useDuplicateHotkey: NamedHotKeyHook = (...args) =>
  usePlatformKeys('Meta + D', 'Ctrl + D', ...args);
export const useRemoveHotkey: NamedHotKeyHook = (...args) =>
  usePlatformKeys('Meta + D', 'Ctrl + D', ...args);
export const useZoomInHotkey: NamedHotKeyHook = (...args) =>
  usePlatformKeys('Meta + =', 'Ctrl + =', ...args);
export const useZoomOutHotkey: NamedHotKeyHook = (...args) =>
  usePlatformKeys('Meta + -', 'Ctrl + -', ...args);
export const useFindHotkey: NamedHotKeyHook = (...args) =>
  usePlatformKeys('Meta + F', 'Ctrl + F', ...args);
export const useGroupSelectedHotkey: NamedHotKeyHook = (...args) =>
  usePlatformKeys('Meta + G', 'Ctrl + G', ...args);
