import { useNavigate } from 'react-router';

import styles from './LoginLink.module.scss';
import { useDestroySession } from '@/hooks/useAuth';

export const LoginLink = () => {
  const destroySession = useDestroySession();
  const navigate = useNavigate();
  return (
    <p className={styles.login}>
      Already subscribed?{' '}
      <span
        onClick={async () => {
          await destroySession();
          navigate('/login');
        }}
        className={styles.link}
      >
        Click here
      </span>
    </p>
  );
};
