import { getSubscriptionInfo, refetchSubscriptionInfo } from '@spaceduck/api';
import { useQuery } from '@tanstack/react-query';
import { QueryDisabledError } from './errors';
import { useSearchParams } from 'react-router';

export const billingKeys = {
  info: (workspaceId: string | null) => ['billing', 'info', workspaceId] as const,
};

export const useGetSubscriptionInfo = (workspaceId: string | null) => {
  const enabled = !!workspaceId;
  const [searchParams] = useSearchParams();
  return useQuery({
    enabled,
    queryKey: billingKeys.info(workspaceId),
    queryFn: async () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      const subscriptionInfo = await getSubscriptionInfo(workspaceId);
      if (!subscriptionInfo.active && searchParams.get('refetchSubscription')) {
        await refetchSubscriptionInfo(workspaceId);
        return await getSubscriptionInfo(workspaceId);
      }
      return subscriptionInfo;
    },
  });
};
