import { type Location, Navigate, useLocation, useSearchParams } from 'react-router';

import Head from '@components/Head';
import LoadingPlaceholder from '@components/LoadingPlaceholder';
import Login from '@components/login/Login';
import { loginPurposeSchema } from '@components/login/schema';
import { useSession } from '@hooks/useAuth';
import { asSafeUrlRaw } from '@lib/safeUrl';

function getFromUrl(location: Location) {
  if (!location.state?.from) return '/';
  const { pathname = '', search = '', hash = '' } = location.state.from;

  return `${pathname}${search}${hash}`;
}

const getTo = (from: string, nextUrl: string | null) => {
  if (nextUrl) {
    const safeUrl = asSafeUrlRaw(nextUrl);
    return safeUrl.pathname + safeUrl.search + safeUrl.hash;
  }
  return from;
};

const loginPurposeQuerySchema = loginPurposeSchema.nullable().catch(null);

export default function LoginPage() {
  const session = useSession();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const from = getFromUrl(location);
  const nextSearchParam = searchParams.get('next');
  const userHint = searchParams.get('user_hint');
  const purpose = loginPurposeQuerySchema.parse(searchParams.get('purpose'));

  if (session?.kind === 'authenticated') {
    const to = getTo(from, nextSearchParam);

    if (to.startsWith('/_') || to.startsWith('https:')) {
      window.location.replace(to);
      return <p>Success!</p>;
    }

    return <Navigate to={to} replace={true} />;
  }

  if (!session) {
    return <LoadingPlaceholder center />;
  }

  return (
    <>
      <Head title="Login" />
      <Login userHint={userHint} purpose={purpose} />
    </>
  );
}
