import { useCallback, useMemo, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useLocation } from 'react-router';
import { useMutation } from '@tanstack/react-query';

import {
  createLoginRequest,
  VerifyCodeRejectedPayloadSchema,
  verifyLoginCode,
} from '@spaceduck/api';

import { toastApiErrorOr } from '@api/util';
import { useRefreshSession } from '@/hooks/useAuth';
import { trackEvent } from '@lib/analytics/google';
import createToast from '@utils/createToast';
import CodeForm, { type CodeFormData } from './CodeForm';
import EmailForm, { type EmailFormData } from './EmailForm';
import type { LoginPurpose } from './schema';

type LoginParams = {
  userHint: string | null;
  purpose: LoginPurpose | null;
};

const useCreateLoginRequest = () => {
  return useMutation({
    mutationFn: createLoginRequest,
    mutationKey: ['passwordless-create-request'],
    onError: (error) => {
      toastApiErrorOr(error, 'Failed to create login request', {
        iconVariant: 'warning',
        titleText: 'Login failed',
        bodyText: 'Failed to create login request. Please try again later.',
      });
    },
  });
};

const useVerifyLoginCode = (onSuccess?: () => void, onInvalid?: () => void) => {
  const refresh = useRefreshSession();
  return useMutation({
    mutationKey: ['passwordless-verify-request'],
    mutationFn: verifyLoginCode,
    onSuccess: async () => {
      trackEvent('login', { method: 'magicCode' });
      onSuccess?.();
      await refresh();
    },
    onError: (error) => {
      if (onInvalid) {
        onInvalid();
        return;
      }

      toastApiErrorOr(error, 'Failed to verify login request', {
        iconVariant: 'warning',
        titleText: 'Login failed',
        bodyText: 'Failed to verify login request. Please try again later.',
      });
    },
  });
};

const Login = ({ userHint, purpose }: LoginParams) => {
  const location = useLocation();
  const nextUrl = new URLSearchParams(location.search).get('next');
  const [email, setEmail] = useState<string | null>(null);

  const {
    mutateAsync: createLoginRequest,
    data: requestId,
    status,
    variables,
    reset,
  } = useCreateLoginRequest();
  const {
    mutateAsync: verifyLoginCode,
    status: verifyStatus,
    error: verifyError,
  } = useVerifyLoginCode(undefined, () => {
    createToast({
      titleText: 'Invalid Code',
      bodyText:
        'The code you entered is invalid. Please check your email and try again.',
      iconVariant: 'danger',
    });
  });

  const handleSubmit = useCallback<SubmitHandler<EmailFormData>>(
    async ({ email }) => {
      await createLoginRequest({ email, next: nextUrl });
      // Once successfully created, we want this email to be "sticky"
      setEmail(email);
    },
    [createLoginRequest, nextUrl]
  );

  const handleResend = useCallback(async () => {
    if (!variables || status === 'pending') {
      return;
    }
    await createLoginRequest(variables);
  }, [variables, status]);

  const submitCodeHandler = useCallback<SubmitHandler<CodeFormData>>(
    async ({ code }) => {
      if (!requestId) {
        throw new Error('Missing login request ID during code validation');
      }
      await verifyLoginCode({ requestId, code });
    },
    [verifyLoginCode, requestId]
  );

  const verifyErrorReason = useMemo(() => {
    if (verifyStatus !== 'error') {
      return null;
    }
    const result = VerifyCodeRejectedPayloadSchema.safeParse(verifyError);
    if (!result.success) {
      return verifyError.message;
    }
    return result.data.info.reason;
  }, [verifyStatus, verifyError]);

  const handleReset = useCallback(() => {
    setEmail(null);
    reset();
  }, []);

  if (status === 'success' || email) {
    // Compiler cannot derive that this is non-null ):
    const targetEmail = (variables?.email ?? email)!;
    return (
      <CodeForm
        error={verifyErrorReason}
        handleBackClick={handleReset}
        isLoadingVerify={verifyStatus === 'pending'}
        isLoadingRequestId={status === 'pending'}
        onResendRequest={handleResend}
        onSubmit={submitCodeHandler}
        targetEmail={targetEmail}
      />
    );
  }

  return (
    <EmailForm
      onSubmit={handleSubmit}
      isLoading={status === 'pending'}
      nextUrl={nextUrl || undefined}
      userHint={userHint ?? variables?.email}
      purpose={purpose}
    />
  );
};

export default Login;
