import { useMutation, useMutationState } from '@tanstack/react-query';

import {
  type BillingInterval,
  getManageSubscriptionLink,
  getUpgradeSubscriptionLink,
  type Profession,
} from '@spaceduck/api';
import { sleep } from '@spaceduck/utils';

import { toastApiErrorOr } from '@/api/util';
import { identity } from '@/utils/functional';

export type ManageSubscriptionParams =
  | { workspaceId: string; action: 'manage' }
  | {
      workspaceId: string;
      action: 'create';
      interval: BillingInterval;
      profession?: Profession;
    };

const navigateToManageSubscriptionLink = async (params: ManageSubscriptionParams) => {
  let subscriptionLink: Awaited<ReturnType<typeof getManageSubscriptionLink>>;
  try {
    if (params.action === 'manage') {
      subscriptionLink = await getManageSubscriptionLink(params.workspaceId);
    } else {
      subscriptionLink = await getUpgradeSubscriptionLink(
        params.workspaceId,
        params.interval,
        params.profession
      );
    }
  } catch (error) {
    return toastApiErrorOr(error, 'Failed to create subscription link', {
      iconVariant: 'warning',
      titleText: 'Subscription Management Error',
      bodyText:
        'An unknown error occurred opening subscription management. Please try again later',
    });
  }
  window.location.href = subscriptionLink.redirectUrl;
  // Give some buffer for the redirect to actually happen
  await sleep(1000);
};

export const useManageSubscription = () => {
  const mutationKey = ['navigateToManageSubscriptionLink'];
  const isPending = useMutationState({
    filters: { mutationKey },
    select: (mutation) => mutation.state.status === 'pending',
  });
  const isAnyPending = isPending.find(identity) ?? false;
  const mutation = useMutation({
    mutationKey,
    mutationFn: async (params: ManageSubscriptionParams) => {
      await navigateToManageSubscriptionLink(params);
    },
  });
  return { isAnyPending, ...mutation };
};
