import { isProject, type MediaGroupDTO, type ProjectDTO } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';
import { exists } from '@spaceduck/utils';

import { activeProjectKeys } from '@/const';
import { useMediaGroupDetail } from '@api/mediaGroup';
import { useWorkspaceProjects } from '@api/workspace';
import { ProjectMode } from '@components/icons';
import { useMediaGroupTransfer } from '@hooks/useMediaGroupTransfer';
import { useMediaGroupPermissions } from '@hooks/usePermissions';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import Button from '@ui/Button';
import type { ContextMenuItemProps } from '@ui/ContextMenu';
import { RecursiveDropdownMenu, Separator, TriggerButton } from '@ui/DropdownMenu';
import dropdownMenuStyles from '@ui/DropdownMenu.module.scss';
import { copyUrlToClipboard } from '@utils/copyToClipboard';
import { downloadUrl } from '@utils/download';
import { useMemo } from 'react';
import { DetailsModalTooltip } from './comments/DetailsModalTooltip';

const { Move } = Icon16;
const { Copy, Download, Link, Menu, RepositoryBrowse, Right, TrashCan } = Icon24;

const MENU_ITEM_COPY_LINK: ContextMenuItemProps = {
  content: <TriggerButton iconBefore={<Link size={20} />}>Copy link</TriggerButton>,
  onClick: () => copyUrlToClipboard(),
};

const SEPARATOR: ContextMenuItemProps = {
  content: <Separator color={1} />,
  isSeparator: true,
};

export default function DetailsModalActionsDropdown({
  mediaGroupId,
}: {
  mediaGroupId: string;
}) {
  const isDraggingOnBoard = useDetailsModalStore((state) => state.isDraggingOnBoard);
  const { data, status } = useMediaGroupDetail(mediaGroupId, {
    enabled: !isDraggingOnBoard,
  });

  if (status !== 'success') {
    return null;
  }

  return <DetailsModalActionsDropdownImpl mediaGroup={data.mediaGroup} />;
}

const DetailsModalActionsDropdownImpl = ({
  mediaGroup,
}: { mediaGroup: MediaGroupDTO }) => {
  const mediaGroupId = mediaGroup.id;
  const projectId = mediaGroup.project?.id ?? null;

  const { canEdit } = useMediaGroupPermissions(mediaGroup);
  const { data: projectsData } = useWorkspaceProjects(mediaGroup.workspace.id ?? null, {
    status: activeProjectKeys,
    flattenStacks: true,
  });
  const projects = projectsData?.projects ?? [];
  const { copy, move, delete: deleteMG } = useMediaGroupTransfer(mediaGroup);

  const otherProjects = useMemo(
    () =>
      projectId
        ? projects
            .filter(isProject)
            .filter((workspaceProject) => workspaceProject.id !== projectId)
        : projects,
    [projectId, projects]
  );

  const menuItemCopy = useMemo(
    (): ContextMenuItemProps => ({
      content: (
        <TriggerButton iconBefore={<Copy size={16} />} iconAfter={<Right />}>
          Copy to...
        </TriggerButton>
      ),
      subMenu: makeAddToProjectMenu({
        projects: otherProjects.filter(isProject),
        onAddToProject: (projectId: string) => {
          copy({ mediaGroupId, projectId });
        },
        onAddToRepository: () => {
          copy({ mediaGroupId });
        },
      }),
    }),
    [mediaGroupId, otherProjects, copy]
  );

  const menuItemMove = useMemo(
    (): ContextMenuItemProps => ({
      content: (
        <TriggerButton iconBefore={<Move size={20} />} iconAfter={<Right />}>
          Move to...
        </TriggerButton>
      ),
      subMenu: makeAddToProjectMenu({
        projects: otherProjects.filter(isProject),
        onAddToProject: (projectId: string) => {
          move({ mediaGroupIds: [mediaGroupId], projectId });
        },
        onAddToRepository:
          (projectId &&
            (() => {
              move({
                mediaGroupIds: [mediaGroupId],
              });
            })) ||
          undefined,
      }),
    }),
    [mediaGroupId, projectId, otherProjects, move]
  );

  const originalAssetUrl = mediaGroup.media[0]?.originalAssetUrl ?? null;
  const processedAssetUrl = mediaGroup.media[0]?.assetUrl ?? null;

  const menuItemDownload: ContextMenuItemProps | null = useMemo(
    () =>
      originalAssetUrl || processedAssetUrl
        ? {
            content: (
              <TriggerButton iconBefore={<Download size={20} />} iconAfter={<Right />}>
                Download
              </TriggerButton>
            ),
            width: 212,
            subMenu: [
              {
                content: (
                  <TriggerButton
                    iconBefore={<Download size={20} />}
                    onClick={() => downloadUrl(originalAssetUrl || processedAssetUrl!)}
                  >
                    Download {originalAssetUrl && 'original'}
                  </TriggerButton>
                ),
              },
            ],
          }
        : null,
    [originalAssetUrl]
  );

  const viewersMenuItem: ContextMenuItemProps[] = useMemo(
    () => [MENU_ITEM_COPY_LINK, SEPARATOR, menuItemDownload].filter(exists),
    [menuItemDownload]
  );

  const editorsMenuItems: ContextMenuItemProps[] = useMemo(
    () =>
      [
        MENU_ITEM_COPY_LINK,
        ...(otherProjects.length ? [SEPARATOR, menuItemCopy, menuItemMove] : []),
        SEPARATOR,
        menuItemDownload,
        {
          content: (
            <TriggerButton iconBefore={<TrashCan size={20} />}>Delete</TriggerButton>
          ),
          onClick: () => deleteMG(mediaGroup.id),
        },
      ].filter(exists),
    [menuItemCopy, menuItemMove, menuItemDownload, mediaGroup, deleteMG]
  );

  return (
    <RecursiveDropdownMenu
      isUnstyled={false}
      items={canEdit ? editorsMenuItems : viewersMenuItem}
      dropdownMenuProps={{
        width: 240,
        isPadded: true,
        align: 'end',
      }}
      showIndicator={false}
      dropdownMenuItemClassName={dropdownMenuStyles.menuItem}
    >
      <span>
        <DetailsModalTooltip content="Options" size="medium" variant="secondary">
          <Button variant="icon">
            <Menu size={20} />
          </Button>
        </DetailsModalTooltip>
      </span>
    </RecursiveDropdownMenu>
  );
};

const makeAddToProjectMenu = ({
  projects,
  onAddToProject,
  onAddToRepository,
}: {
  projects: ProjectDTO[];
  onAddToProject: (projectId: string) => void;
  onAddToRepository?: () => void;
}): ContextMenuItemProps[] => {
  const addToRepositoryOption = [];
  if (onAddToRepository) {
    addToRepositoryOption.push({
      content: (
        <TriggerButton iconBefore={<RepositoryBrowse size={20} />}>
          Drafts
        </TriggerButton>
      ),
      onClick: onAddToRepository,
    });
  }
  const projectOptions = projects.map(({ id, label, mode }) => ({
    content: (
      <TriggerButton iconBefore={<ProjectMode mode={mode} size={20} />}>
        {label}
      </TriggerButton>
    ),
    onClick: () => {
      onAddToProject?.(id);
    },
  }));

  return [...addToRepositoryOption, ...projectOptions];
};
