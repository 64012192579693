import {
  type Ref,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import type { CreateMediaGroupSchema } from '@spaceduck/api';

import { useCreateMediaGroup } from '@api/mediaGroup';
import { toastApiErrorOr } from '@api/util';
import {
  type CreateBookmarkData,
  useBookmarkModal,
} from '@components/CreateBookmarkModal';
import { useMetaKey } from '@hooks/useMetaKey';
import { useFileUploadWrapper } from '@hooks/useFileUploadWrapper';
import useWorkspaceId from '@hooks/useWorkspaceId';
import DropdownMenu, { RecursiveDropdownMenuItem, Separator } from '@ui/DropdownMenu';
import { BoardStoreContext } from '../context/boardContext';
import { useFrames } from '../hooks/useFrames';
import { useNodes } from '../hooks/useNodes';
import styles from './BoardContextMenu.module.scss';

type Coordinates = {
  x: number;
  y: number;
};

export type BoardContextMenuCoordinates = Coordinates | null;

export const BoardContextMenu = ({
  coordinates,
}: {
  coordinates: BoardContextMenuCoordinates;
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [latchedCoords, setLatchedCoords] = useState<BoardContextMenuCoordinates>(null);

  useEffect(() => {
    if (coordinates === null) {
      return;
    }
    setLatchedCoords(coordinates);
  }, [coordinates]);

  const triggerFile = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  return (
    <>
      {!!coordinates && (
        <Menu openFileBrowser={triggerFile} x={coordinates.x} y={coordinates.y} />
      )}
      <FileUploader ref={fileInputRef} coordinates={latchedCoords} />
    </>
  );
};

const Menu = ({
  openFileBrowser,
  x,
  y,
}: {
  openFileBrowser: () => void;
  x: number;
  y: number;
}) => {
  const metaKey = useMetaKey();

  const {
    addFloatingTextNode,
    addMediaNode,
    clipboardItems,
    createDocumentNode,
    pasteNodes,
  } = useNodes();

  const { addFrame } = useFrames();

  const { mutateAsync: createMediaGroup } = useCreateMediaGroup();
  const createLink = useCallback(
    async (data: Omit<CreateMediaGroupSchema, 'kind'>) => {
      const { projectId, workspaceId, ...rest } = data;
      let response: Awaited<ReturnType<typeof createMediaGroup>>;
      try {
        response = await createMediaGroup({
          kind: 'bookmark',
          projectId: projectId,
          workspaceId: !projectId ? workspaceId : undefined,
          ...rest,
        });
      } catch (error) {
        return toastApiErrorOr(error, 'Failed to create item from context menu', {
          iconVariant: 'warning',
          titleText: 'Failed to Create Item',
          bodyText:
            'An unknown error occurred while creating item. Please try again later',
        });
      }
      const { mediaGroupId } = response;
      addMediaNode(mediaGroupId, x, y);
    },
    [createMediaGroup, addMediaNode]
  );

  const workspaceId = useWorkspaceId();
  const boardContext = useContext(BoardStoreContext);
  const projectId = boardContext?.projectId;

  const { open: openCreateBookmarkModal } = useBookmarkModal();
  const launchCreateBookmarkModal = useCallback(() => {
    openCreateBookmarkModal({
      onSubmit: (data: CreateBookmarkData) => {
        createLink({
          workspaceId: workspaceId ?? undefined,
          projectId: projectId,
          url: data.url,
        });
      },
    });
  }, [createLink, workspaceId, projectId]);

  const pasteItem = useMemo(
    () => ({
      content: 'Paste',
      onClick: () => pasteNodes?.({ x, y }),
      shortcut: `${metaKey} V`,
    }),
    [pasteNodes, x, y]
  );

  const pasteFromClipboard = useMemo(() => {
    return {
      content: 'Paste from clipboard',
      onClick: async () => {
        const clipboardContent = await navigator.clipboard.read();
        if (
          !(clipboardContent.length && clipboardContent[0]?.types[0] === 'text/plain')
        )
          return;

        const clipboardContentText = await navigator.clipboard.readText();

        addFloatingTextNode(x, y, { content: clipboardContentText });
      },
    };
  }, [x, y, addFloatingTextNode]);

  const addDocumentItem = useMemo(
    () => ({
      content: 'Document',
      onClick: () => createDocumentNode(x, y),
      shortcut: 'D',
    }),
    [createDocumentNode, x, y]
  );

  const addFloatingTextItem = useMemo(
    () => ({
      content: 'Text',
      onClick: () => addFloatingTextNode(x, y),
      shortcut: 'T',
    }),
    [createDocumentNode, x, y]
  );

  const addFrameItem = useMemo(
    () => ({
      content: 'Frame',
      onClick: () => addFrame(x, y),
      shortcut: 'F',
    }),
    [addFrame, x, y]
  );

  const addLinkItem = useMemo(
    () => ({
      content: 'Link',
      onClick: () => launchCreateBookmarkModal(),
    }),
    [launchCreateBookmarkModal]
  );

  const uploadItem = useMemo(
    () => ({
      content: 'Upload',
      onClick: openFileBrowser,
    }),
    [openFileBrowser]
  );

  const [hasClipboardContent, setHasClipboardContent] = useState(false);
  useEffect(() => {
    async function checkClipboard() {
      if (!document.hasFocus()) return;

      const content = await navigator.clipboard.read();

      if (content?.[0]?.types?.[0] === 'text/plain') {
        setHasClipboardContent(true);
      }
    }

    checkClipboard();
  }, []);

  return (
    <DropdownMenu
      key={`${x},${y}`}
      modal={false}
      isPadded
      open
      sideOffset={6}
      triggerContent={<div style={{ position: 'fixed', top: y, left: x }} />}
      width={262}
    >
      {((!!clipboardItems && !!pasteNodes) || hasClipboardContent) && (
        <>
          {clipboardItems && <RecursiveDropdownMenuItem item={pasteItem} />}
          {hasClipboardContent && (
            <RecursiveDropdownMenuItem item={pasteFromClipboard} />
          )}
          <Separator className={styles.separator} color={1} />
        </>
      )}
      <RecursiveDropdownMenuItem item={addDocumentItem} />
      <RecursiveDropdownMenuItem item={addFloatingTextItem} />
      <RecursiveDropdownMenuItem item={addFrameItem} />
      <Separator className={styles.separator} color={1} />
      <RecursiveDropdownMenuItem item={addLinkItem} />
      <RecursiveDropdownMenuItem item={uploadItem} />
    </DropdownMenu>
  );
};

const DISPLAY_NONE = { display: 'none' } as const;

const FileUploader = forwardRef(
  (
    { coordinates }: { coordinates: BoardContextMenuCoordinates },
    ref: Ref<HTMLInputElement>
  ) => {
    const { addMediaNode } = useNodes();
    const workspaceId = useWorkspaceId();
    const boardContext = useContext(BoardStoreContext);
    const projectId = boardContext?.projectId;

    const { handleChange } = useFileUploadWrapper(
      {
        workspaceId,
        projectId,
      },
      {
        onSuccess: async (mediaGroupId: string) => {
          if (!coordinates) return;
          addMediaNode(mediaGroupId, coordinates.x, coordinates.y);
        },
      }
    );

    return (
      <input
        type="file"
        multiple
        ref={ref}
        onChange={handleChange}
        style={DISPLAY_NONE}
      />
    );
  }
);
