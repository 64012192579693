import { Icon16 } from '@spaceduck/icons';
import { BubbleMenu, type Editor, type JSONContent } from '@tiptap/react';
import { upperFirst } from 'lodash';
import { useContext, useEffect, useId, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useParams } from 'react-router';
import type { Instance } from 'tippy.js';

import { useMakeComment } from '@api/comment';
import { CommentTextBox } from '@components/detailsModal/comments/CommentTextbox';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import Menu from '@ui/Menu';
import { NotesContext } from './context/NotesContext';
import EditLinkForm from './nodes/link/EditLinkForm';
import AIChatBox from './nodes/selectionMenu/AIChatBox';
import { type Transformation, handleTransformation } from './transformations';
import styles from './SelectionMenu.module.scss';

type Options = {
  color?: string;
};

type View = 'aiAssistant' | 'menu' | 'link' | 'comment';

const {
  AI,
  Bold,
  BulletedList,
  CodeBlock,
  CodeInline,
  Color,
  Comment,
  TextT,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Highlight,
  Italics,
  Link,
  NumberedList,
  Quote,
  Strikethrough,
  TextCenter,
  TextColor,
  TextLeft,
  TextRight,
  TextHighlight,
  TodoList,
  Underline,
} = Icon16;

export default function SelectionMenu({
  editor,
  disabled = false,
}: {
  editor: Editor | null;
  disabled?: boolean;
}) {
  const [view, setView] = useState<View>('menu');
  const { mediaGroupId } = useParams();
  const detailsModalContent = document.querySelector('#tiptapSelectionMenuRoot');
  const instanceRef = useRef<Instance | null>(null);
  const notesContext = useContext(NotesContext);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const aiAssistantButton = useRef<HTMLButtonElement | null>(null);
  const menuId = useId();

  const [menuBounds, setMenuBounds] = useState(() =>
    calcCenteredRect(notesContext?.containerRefId)
  );
  const calcMenuWidth = () => {
    setMenuBounds(calcCenteredRect(notesContext?.containerRefId));
  };

  useEffect(() => {
    calcMenuWidth();
    window.addEventListener('resize', calcMenuWidth);

    return () => {
      window.removeEventListener('resize', calcMenuWidth);
    };
  }, []);

  const handleContainerClick = (ev: MouseEvent) => {
    const target = ev.target as HTMLElement;
    if (!notesContext?.containerRefId) return;

    const container = document.getElementById(notesContext.containerRefId);
    const menuContainer = document.getElementById(`menuRef-${menuId}`);
    if (!(container || menuContainer)) return;

    if (
      target === aiAssistantButton.current ||
      aiAssistantButton.current?.contains(target)
    ) {
      setView('aiAssistant');
      editor?.setOptions({
        editorProps: {},
      });
    } else if (!container?.contains(target) && !menuRef.current?.contains(target)) {
      instanceRef.current?.hide();
    }
  };

  useEffect(() => {
    if (notesContext?.containerRefId) {
      document.addEventListener('click', handleContainerClick);

      return () => document.removeEventListener('click', handleContainerClick);
    }
  }, [notesContext?.containerRefId]);

  useEffect(() => {
    // Triggers tippy pos recalculation
    window.dispatchEvent(new Event('resize'));

    if (detailsModalContent) {
      detailsModalContent.setAttribute('data-view', view);

      if (!instanceRef.current) {
        const firstChild = detailsModalContent.childNodes[0];
        if (firstChild && '_tippy' in firstChild) {
          const instance = firstChild._tippy as Instance;
          instanceRef.current = instance;
        }
      }

      instanceRef.current?.setProps({
        placement: view === 'aiAssistant' ? 'bottom-start' : 'top',
        getReferenceClientRect:
          view === 'aiAssistant'
            ? () => calcCenteredRect(notesContext?.containerRefId)
            : undefined,
      });
    }
  }, [view]);

  useEffect(() => {
    if (editor?.isFocused) {
      setView('menu');
      if (instanceRef.current) {
        instanceRef.current?.setProps({
          placement: view === 'aiAssistant' ? 'bottom' : 'top',
        });
        const { from, to } = editor.view.state.selection;
        if (from !== to) {
          instanceRef.current?.show?.();
        }
      }
    }
  }, [editor?.isFocused]);

  if (!(editor && detailsModalContent) || !editor.isEditable || !mediaGroupId)
    return null;

  return (
    // BubbleMenu needs to be wrapped - https://github.com/ueberdosis/tiptap/issues/3784
    <div>
      <BubbleMenu
        editor={editor}
        shouldShow={({ state, from, to }) => {
          if (disabled) return false;

          // For detecting drag handles
          if ('node' in state.selection) return false;
          if (from === 0) return false;
          return from !== to;
        }}
        tippyOptions={{
          maxWidth: 'none',
          onClickOutside: () => setView('menu'),
          onHide: () => setView('menu'),
          appendTo: detailsModalContent,
          getReferenceClientRect:
            view === 'aiAssistant'
              ? () => calcCenteredRect(notesContext?.containerRefId)
              : undefined,
        }}
      >
        <div ref={menuRef}>
          {view === 'aiAssistant' && (
            <AIChatBox
              mediaGroupId={mediaGroupId}
              editor={editor}
              width={menuBounds.width}
            />
          )}
          {view === 'link' && (
            <EditLinkForm
              initialValue={editor.getAttributes('link').href}
              initialOpenInNewTab={editor.getAttributes('link').target === '_blank'}
              hideForm={() => setView('menu')}
              onCreateLink={(url, openInNewTab) => {
                editor
                  .chain()
                  .focus()
                  .extendMarkRange('link')
                  .setLink({ href: url, target: openInNewTab ? '_blank' : '_self' })
                  .run();
                setView('menu');
              }}
              onDelete={() => {
                editor.chain().focus().extendMarkRange('link').unsetLink().run();
                setView('menu');
              }}
            />
          )}
          {view === 'comment' && mediaGroupId && (
            <CommentForm
              mediaGroupId={mediaGroupId}
              editor={editor}
              setView={setView}
              view={view}
            />
          )}
          {view === 'menu' && (
            <Menu
              menuItems={[
                {
                  icon: <AI />,
                  ref: aiAssistantButton,
                },
                {
                  isSeparator: true,
                },
                {
                  icon: <TextT />,
                  title: 'Format',
                  tooltip: 'Format',
                  menuItems: [
                    {
                      label: 'Text',
                      icon: <TextT />,
                      onClick: () => handleTransformation(editor, 'text'),
                    },
                    {
                      label: 'Heading 1',
                      icon: <Heading1 />,
                      onClick: () => handleTransformation(editor, 'heading1'),
                    },
                    {
                      label: 'Heading 2',
                      icon: <Heading2 />,
                      onClick: () => handleTransformation(editor, 'heading2'),
                    },
                    {
                      label: 'Heading 3',
                      icon: <Heading3 />,
                      onClick: () => handleTransformation(editor, 'heading3'),
                    },
                    {
                      label: 'Heading 4',
                      icon: <Heading4 />,
                      onClick: () => handleTransformation(editor, 'heading4'),
                    },
                    {
                      label: 'Heading 5',
                      icon: <Heading5 />,
                      onClick: () => handleTransformation(editor, 'heading5'),
                    },
                    {
                      label: 'Heading 6',
                      icon: <Heading6 />,
                      onClick: () => handleTransformation(editor, 'heading6'),
                    },
                    {
                      label: 'Inline code',
                      icon: <CodeInline />,
                      onClick: () => handleTransformation(editor, 'inlineCode'),
                    },
                    {
                      label: 'Code block',
                      icon: <CodeBlock />,
                      onClick: () => handleTransformation(editor, 'codeBlock'),
                    },
                    {
                      label: 'Quote',
                      icon: <Quote />,
                      onClick: () => handleTransformation(editor, 'quote'),
                    },
                    {
                      label: 'Bold',
                      icon: <Bold />,
                      onClick: () => handleTransformation(editor, 'bold'),
                    },
                    {
                      label: 'Strikethrough',
                      icon: <Strikethrough />,
                      onClick: () => handleTransformation(editor, 'strikethrough'),
                    },
                    {
                      label: 'Underline',
                      icon: <Underline />,
                      onClick: () => handleTransformation(editor, 'underline'),
                    },
                    {
                      label: 'Italics',
                      icon: <Italics />,
                      onClick: () => handleTransformation(editor, 'italic'),
                    },
                  ],
                },
                {
                  icon: <TextLeft />,
                  title: 'Text alignment',
                  tooltip: 'Text alignment',
                  menuItems: [
                    {
                      label: 'Left',
                      icon: <TextLeft />,
                      onClick: () => handleTransformation(editor, 'textLeft'),
                    },
                    {
                      label: 'Center',
                      icon: <TextCenter />,
                      onClick: () => handleTransformation(editor, 'textCenter'),
                    },
                    {
                      label: 'Right',
                      icon: <TextRight />,
                      onClick: () => handleTransformation(editor, 'textRight'),
                    },
                  ],
                },
                {
                  icon: <BulletedList />,
                  tooltip: 'Bulleted list',
                  onClick: () => handleTransformation(editor, 'bulletedList'),
                },
                {
                  icon: <NumberedList />,
                  tooltip: 'Numbered list',
                  onClick: () => handleTransformation(editor, 'numberedList'),
                },
                {
                  icon: <TodoList />,
                  tooltip: 'Todo list',
                  onClick: () => handleTransformation(editor, 'todoList'),
                },
                {
                  icon: <Link />,
                  tooltip: 'Link',
                  onClick: (ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    setView('link');
                  },
                },
                {
                  isSeparator: true,
                },
                {
                  icon: <Color />,
                  tooltip: 'Color',
                  title: 'Color',
                  menuItems: Object.getOwnPropertyNames(colors).map((color) =>
                    getColorOption(editor, color, colors[color]!, handleTransformation)
                  ),
                },
                {
                  icon: <Highlight />,
                  tooltip: 'Highlight',
                  menuItems: Object.getOwnPropertyNames(colors).map((color) =>
                    getHighlightOption(
                      editor,
                      color,
                      colors[color]!,
                      handleTransformation
                    )
                  ),
                },
                {
                  isSeparator: true,
                },
                {
                  icon: <CodeInline />,
                  tooltip: 'Code inline',
                  onClick: () => handleTransformation(editor, 'inlineCode'),
                },
                {
                  icon: <CodeBlock />,
                  tooltip: 'Code block',
                  onClick: () => handleTransformation(editor, 'codeBlock'),
                },
                {
                  icon: <Quote />,
                  tooltip: 'Quote',
                  onClick: () => handleTransformation(editor, 'quote'),
                },
                {
                  isSeparator: true,
                },
                {
                  icon: <Comment />,
                  tooltip: 'Comment',
                  onClick: (ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    setView('comment');
                  },
                },
              ]}
            />
          )}
        </div>
      </BubbleMenu>
    </div>
  );
}

const calcCenteredRect = (id?: string | null) => {
  const ref = id ? document.getElementById(id) : null;

  const noRect = {
    width: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  } as DOMRect;

  const allHighlightedElements = ref
    ? Array.from(ref.querySelectorAll('.blurHighlight'))
    : [];
  const embeds = ref ? Array.from(ref.querySelectorAll('.node-content-block')) : [];
  const highlightedElements = allHighlightedElements.filter((el) => {
    return !embeds.find((embed) => embed.contains(el));
  });

  const selection = highlightedElements.at(-1);
  const getWindowSelectionBounds = () => {
    const windowSelection = window.getSelection();
    if (!(windowSelection && windowSelection.rangeCount > 0)) return;
    return windowSelection?.getRangeAt(0).getBoundingClientRect();
  };

  const relativeRect = selection?.getBoundingClientRect() ?? getWindowSelectionBounds();

  if (!relativeRect) return noRect;

  const { width, height, left, right, top, bottom } = relativeRect;

  if (!ref) {
    return {
      width,
      height,
      left,
      right,
      top,
      bottom,
    } as DOMRect;
  }

  const containerRect = ref.getBoundingClientRect();
  const paddingLeft = Number.parseInt(
    getComputedStyle(ref).getPropertyValue('padding-left')
  );
  const paddingRight = Number.parseInt(
    getComputedStyle(ref).getPropertyValue('padding-right')
  );

  const containerLeft =
    typeof containerRect.left === 'number'
      ? containerRect.left + paddingLeft
      : relativeRect.left;

  const containerRight =
    typeof containerRect.right === 'number'
      ? containerRect.right - paddingRight
      : relativeRect.right;

  return {
    width: containerRect.width
      ? Math.min(
          containerRect.width - paddingLeft - paddingRight,
          containerRight - containerLeft
        )
      : relativeRect.width,
    height: relativeRect.height,
    left: containerLeft,
    right: containerRight,
    top: relativeRect.top,
    bottom: relativeRect.bottom,
  } as DOMRect;
};

export const BoardSelectionMenu = ({
  editor,
}: {
  editor: Editor | null;
}) => {
  const detailsModalContent = document.querySelector('#tiptapSelectionMenuRoot');

  if (!(editor && detailsModalContent) || !editor.isEditable) return null;

  return (
    <div>
      <Menu
        menuItems={[
          {
            icon: <TextT />,
            title: 'Format',
            tooltip: 'Format',
            menuItems: [
              {
                label: 'Text',
                icon: <TextT />,
                onClick: () => handleTransformation(editor, 'text'),
              },
              {
                label: 'Heading 1',
                icon: <Heading1 />,
                onClick: () => handleTransformation(editor, 'heading1'),
              },
              {
                label: 'Heading 2',
                icon: <Heading2 />,
                onClick: () => handleTransformation(editor, 'heading2'),
              },
              {
                label: 'Heading 3',
                icon: <Heading3 />,
                onClick: () => handleTransformation(editor, 'heading3'),
              },
              {
                label: 'Heading 4',
                icon: <Heading4 />,
                onClick: () => handleTransformation(editor, 'heading4'),
              },
              {
                label: 'Heading 5',
                icon: <Heading5 />,
                onClick: () => handleTransformation(editor, 'heading5'),
              },
              {
                label: 'Heading 6',
                icon: <Heading6 />,
                onClick: () => handleTransformation(editor, 'heading6'),
              },
              {
                label: 'Inline code',
                icon: <CodeInline />,
                onClick: () => handleTransformation(editor, 'inlineCode'),
              },
              {
                label: 'Code block',
                icon: <CodeBlock />,
                onClick: () => handleTransformation(editor, 'codeBlock'),
              },
              {
                label: 'Quote',
                icon: <Quote />,
                onClick: () => handleTransformation(editor, 'quote'),
              },
              {
                label: 'Bold',
                icon: <Bold />,
                onClick: () => handleTransformation(editor, 'bold'),
              },
              {
                label: 'Strikethrough',
                icon: <Strikethrough />,
                onClick: () => handleTransformation(editor, 'strikethrough'),
              },
              {
                label: 'Underline',
                icon: <Underline />,
                onClick: () => handleTransformation(editor, 'underline'),
              },
              {
                label: 'Italics',
                icon: <Italics />,
                onClick: () => handleTransformation(editor, 'italic'),
              },
            ],
          },
          {
            icon: <BulletedList />,
            tooltip: 'Bulleted list',
            onClick: () => handleTransformation(editor, 'bulletedList'),
          },
          {
            icon: <NumberedList />,
            tooltip: 'Numbered list',
            onClick: () => handleTransformation(editor, 'numberedList'),
          },
          {
            icon: <TodoList />,
            tooltip: 'Todo list',
            onClick: () => handleTransformation(editor, 'todoList'),
          },
          {
            isSeparator: true,
          },
          {
            icon: <Color />,
            tooltip: 'Color',
            title: 'Color',
            menuItems: Object.getOwnPropertyNames(colors).map((color) =>
              getColorOption(editor, color, colors[color]!, handleTransformation)
            ),
          },
          {
            icon: <Highlight />,
            tooltip: 'Highlight',
            menuItems: Object.getOwnPropertyNames(colors).map((color) =>
              getHighlightOption(editor, color, colors[color]!, handleTransformation)
            ),
          },
          {
            isSeparator: true,
          },
          {
            icon: <CodeBlock />,
            tooltip: 'Code block',
            onClick: () => handleTransformation(editor, 'codeBlock'),
          },
          {
            icon: <Quote />,
            tooltip: 'Quote',
            onClick: () => handleTransformation(editor, 'quote'),
          },
        ]}
      />
    </div>
  );
};

export const colors: Record<string, string> = {
  default: 'none',
  red: '#FB7185',
  orange: '#FDBA74',
  yellow: '#FDDF74',
  green: '#6EE599',
  teal: '#57DDCC',
  blue: '#80B7FB',
  purple: '#A29AFA',
  gray: '#B5B7CA',
};

export const getColorOption = (
  editor: Editor,
  color: string,
  hex: string,
  cb: (editor: Editor, transformation: Transformation, options?: Options) => void
) => ({
  label: upperFirst(color),
  icon: <TextColor color={hex === 'none' ? '#F0F1F4' : hex} />,
  onClick: () => cb(editor, 'color', { color: hex }),
});

const getHighlightOption = (
  editor: Editor,
  color: string,
  hex: string,
  cb: (editor: Editor, transformation: Transformation, options?: Options) => void
) => ({
  label: upperFirst(color),
  icon: <TextHighlight color={hex === 'none' ? '#F0F1F4' : hex} />,
  onClick: () => cb(editor, 'highlight', { color: `${hex}66` }),
});

const CommentForm = ({
  editor,
  mediaGroupId,
  setView,
  view,
}: {
  editor: Editor;
  mediaGroupId: string;
  setView: React.Dispatch<React.SetStateAction<View>>;
  view: View;
}) => {
  const { mutateAsync } = useMakeComment(mediaGroupId);

  const handleSubmit = async (content: string, document?: JSONContent) => {
    const { id } = await mutateAsync({
      content,
      document,
    });

    editor
      .chain()
      .focus()
      .setComment()
      .updateAttributes('comment', { 'data-comment-id': id })
      .run();

    setView('menu');
  };

  const setIsInlineCommenting = useDetailsModalStore(
    (state) => state.setIsInlineCommenting
  );

  useEffect(() => {
    setIsInlineCommenting(true);

    return () => {
      setIsInlineCommenting(false);
    };
  }, []);

  useHotkeys(
    'Escape',
    (ev) => {
      setView('menu');
      ev.preventDefault();
      ev.stopPropagation();
    },
    {
      enabled: view === 'comment',
    },
    [view]
  );

  return (
    <div className={styles.commentTextBox}>
      <CommentTextBox
        className={styles.createCommentTextBox}
        emojiPickerAsTippy={true}
        isLoading={false}
        onSubmit={handleSubmit}
        onCancel={() => setView('menu')}
      />
    </div>
  );
};
