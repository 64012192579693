type DotsProps = {
  numberOfSlides: number;
  goToSlide: (index: number) => void;
  selectedIndex: number;
};

import clsx from 'clsx';
import styles from './Dots.module.scss';

export const Dots = ({ goToSlide, numberOfSlides, selectedIndex }: DotsProps) => {
  if (!numberOfSlides) return;
  const slides = Array(3).fill(null);

  return (
    <div className={styles.dots}>
      {slides.map((_, idx) => {
        return (
          <button
            className={clsx(styles.dot, selectedIndex === idx && styles.active)}
            key={idx}
            onClick={() => goToSlide(idx)}
            type="button"
          />
        );
      })}
    </div>
  );
};
