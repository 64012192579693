import { useCallback, useContext } from 'react';
import type { Edge, Node, NodeChange, NodeDimensionChange } from '@xyflow/react';
import { debounce, isEqual, throttle } from 'lodash';

import type { MediaGroupKind, MediaGroupPatch } from '@spaceduck/api';

import {
  useCopyMediaGroup,
  useCreateMediaGroup,
  usePatchMediaGroup,
} from '@api/mediaGroup';
import useWorkspaceId from '@hooks/useWorkspaceId';
import createToast from '@utils/createToast';
import { useBoardStore } from './useBoardStore';
import { BoardStoreContext } from '../context/boardContext';
import { useShallow } from 'zustand/shallow';

export const usePersist = ({ mediaGroupId }: { mediaGroupId?: string }) => {
  const workspaceId = useWorkspaceId();
  const boardContext = useContext(BoardStoreContext);
  const projectId = boardContext?.projectId;

  const { mutateAsync: copyMediaGroupToProject } = useCopyMediaGroup();
  const { mutateAsync: createMediaGroup } = useCreateMediaGroup();
  const { mutateAsync: updateMediaGroup } = usePatchMediaGroup();

  const { lastSaved, setLastSaved } = useBoardStore(
    useShallow((state) => ({
      lastSaved: state.lastSaved,
      setLastSaved: state.setLastSaved,
    }))
  );

  const copyEntry = useCallback(
    async ({
      mediaGroupId,
      showToast = true,
    }: {
      mediaGroupId: string;
      showToast?: boolean;
    }) => {
      if (!mediaGroupId) {
        console.error('No media groups passed into copy function');
        return;
      }

      const res = await copyMediaGroupToProject({
        mediaGroupIds: [mediaGroupId],
        projectId,
        mode: 'copy',
      });

      if (res.kind !== 'success') {
        createToast({
          titleText: 'Copy failed',
          bodyText: 'Media group could not be copied',
          iconVariant: 'warning',
        });

        return;
      }

      if (showToast) {
        createToast({
          titleText: 'Copy successful',
          bodyText: 'Media group copied',
          iconVariant: 'success',
        });
      }

      return res;
    },
    [copyMediaGroupToProject, projectId]
  );

  const createEntry = useCallback(
    async ({
      kind,
      showToast = true,
    }: {
      kind: MediaGroupKind;
      showToast?: boolean;
    }) => {
      if (!kind) {
        console.error('No kind passed into create function');
        return;
      }

      const res = await createMediaGroup({
        workspaceId: !projectId ? workspaceId : undefined,
        projectId,
        kind,
      });

      if (res.kind !== 'success') {
        createToast({
          titleText: 'Create failed',
          bodyText: 'Media group could not be created',
          iconVariant: 'warning',
        });

        return;
      }

      if (showToast) {
        createToast({
          titleText: 'Create successful',
          bodyText: 'Media group created',
          iconVariant: 'success',
        });
      }

      return res;
    },
    [createMediaGroup, workspaceId, projectId]
  );

  const updateEntry = useCallback(
    async ({
      patch,
      showToast = true,
    }: {
      patch: MediaGroupPatch;
      showToast?: boolean;
    }) => {
      if (!mediaGroupId) {
        console.error('No MediaGroupId passed into update function');
        return;
      }

      if (!patch) {
        console.error('No patch passed into update function');
        return;
      }

      if (
        patch.board?.nodes?.filter(
          (node) => Number.isNaN(node.position.x) || Number.isNaN(node.position.y)
        ).length
      ) {
        return;
      }

      const transformedPatch = transformPatch(patch);

      if (isEqual(transformedPatch.board ?? null, lastSaved)) return;
      setLastSaved(transformedPatch.board ?? null);

      const res = await updateMediaGroup({ mediaGroupId, patch: transformedPatch });

      if (res.kind !== 'success') {
        createToast({
          titleText: 'Update failed',
          bodyText: 'Media group could not be updated',
          iconVariant: 'warning',
        });

        return;
      }

      if (showToast) {
        createToast({
          titleText: 'Update successful',
          bodyText: 'Media group updated',
          iconVariant: 'success',
        });
      }

      return res;
    },
    [lastSaved, mediaGroupId, updateMediaGroup]
  );

  const useDebouncedUpdateEntry = useCallback(
    (delayInMs?: number) =>
      useCallback(debounce(updateEntry, delayInMs ?? 200), [updateEntry]),
    [updateEntry]
  );

  const useThrottleUpdateEntry = useCallback(
    (delayInMs?: number) =>
      useCallback(throttle(updateEntry, delayInMs ?? 200, { leading: true }), [
        updateEntry,
      ]),
    [updateEntry]
  );

  const updateEntryNodes = useCallback(
    async (nodes: Node[], showToast?: boolean) => {
      return updateEntry({
        patch: {
          board: {
            nodes,
          },
        },
        showToast,
      });
    },
    [updateEntry]
  );

  const useDebouncedUpdateEntryNodes = useCallback(
    (delayInMs?: number) =>
      useCallback(
        debounce((nodes: Node[], showToast?: boolean) => {
          updateEntryNodes(nodes, showToast);
        }, delayInMs ?? 200),
        [updateEntryNodes]
      ),
    [updateEntryNodes]
  );

  const useThrottleUpdateEntryNodes = useCallback(
    (delayInMs?: number) =>
      useCallback(
        throttle(
          (nodes: Node[], showToast?: boolean) => {
            updateEntryNodes(nodes, showToast);
          },
          delayInMs ?? 200,
          {
            leading: true,
          }
        ),
        [updateEntryNodes]
      ),
    [updateEntryNodes]
  );

  const updateEntryEdges = useCallback(
    async (edges: Edge[], showToast?: boolean) => {
      return updateEntry({
        patch: {
          board: {
            edges,
          },
        },
        showToast,
      });
    },
    [updateEntry]
  );

  const useDebouncedUpdateEntryEdges = useCallback(
    (delayInMs?: number) =>
      useCallback(
        debounce(
          (edges: Edge[], showToast?: boolean) => updateEntryEdges(edges, showToast),
          delayInMs ?? 200
        ),
        [updateEntryEdges]
      ),
    [updateEntryEdges]
  );

  const useThrottleUpdateEntryEdges = useCallback(
    (delayInMs?: number) =>
      useCallback(
        throttle(
          (edges: Edge[], showToast?: boolean) => updateEntryEdges(edges, showToast),
          delayInMs ?? 200,
          {
            leading: true,
          }
        ),
        [updateEntryEdges]
      ),
    [updateEntryEdges]
  );

  return {
    copyEntry,
    createEntry,
    shouldTriggerSaveFromNodeChanges,
    updateEntry,
    updateEntryNodes,
    updateEntryEdges,
    useDebouncedUpdateEntry,
    useDebouncedUpdateEntryNodes,
    useDebouncedUpdateEntryEdges,
    useThrottleUpdateEntry,
    useThrottleUpdateEntryNodes,
    useThrottleUpdateEntryEdges,
  };
};

function transformPatch(patch: MediaGroupPatch): MediaGroupPatch {
  if (!patch.board) return patch;

  const updatedEdges =
    patch.board.edges?.map((edge) => {
      const { selected, ...updatedEdges } = edge;

      return updatedEdges;
    }) ?? undefined;

  const updatedNodes =
    patch.board.nodes
      ?.filter((node) => node.id !== 'menuPlaceholder')
      .map((node) => {
        const { className, dragging, measured, selected, ...newNode } = node;
        const { isInEditMode, ...newNodeData } = newNode.data;

        const updatedNode = {
          ...newNode,
          data: newNodeData,
          height:
            (node.height || node.data.height || node.measured?.height) ?? undefined,
        };

        return updatedNode;
      }) ?? undefined;

  return { board: { edges: updatedEdges, nodes: updatedNodes } };
}

const shouldTriggerSaveFromNodeChanges = (changes: NodeChange[]) => {
  const hasDragging = changes.find((change) => {
    if ('dragging' in change && change.dragging) return true;
    return false;
  });

  if (hasDragging) {
    return false;
  }

  const nonSelectChanges = changes.filter((change) => change.type !== 'select');

  if (!nonSelectChanges.length) {
    // Only selecting
    return false;
  }

  const draggingPositionDoneChanges = changes.filter(
    (change) => change.type === 'position' && !change.dragging
  );

  if (draggingPositionDoneChanges.length) {
    // Done re-positioning
    return true;
  }

  const resizingDoneChanges = changes.filter((change) => {
    if (change.type === 'dimensions' && !change.resizing) {
      return true;
    }

    return false;
  }) as NodeDimensionChange[];

  if (resizingDoneChanges.length) {
    // Done resizing
    return true;
  }

  const saveableChanges = changes.filter((change) => {
    if (change.type === 'add') return true;
    if (change.type === 'remove') return true;
    if (change.type === 'replace') return true;

    return false;
  });

  if (saveableChanges.length) {
    return true;
  }

  return false;
};
